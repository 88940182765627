<template>
    <div class="tooltip-container" ref="container" @click.self="$emit('close')">
        <div :id="id" class="tooltip" @mouseleave="autoClose" @mouseenter="stopHiding = true">
            <div class="tooltip-content">
                <div class="tooltip-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tooltip',
    props: ['coordinates', 'posType', 'id'],
    data() {
        return {
            isHiding: false,
            stopHiding: false,
        }
    },
    methods: {
        autoClose() {
            // this.stopHiding = false;
            // if (!this.isHiding) {
            //     this.isHiding = true;
            //     setTimeout(() => {
            //         const tooltip = document.getElementById(this.id);
            //         if (tooltip) {
            //             if (this.stopHiding) {
            //                 this.autoAppear();
            //             } else {
            //                 tooltip.classList.add('hiding');
            //             }
            //         }
            //         setTimeout(() => {
            //             if (this.stopHiding) {
            //                 this.autoAppear();
            //             } else {
            //                 this.$emit('close');
            //                 this.isHiding = false;
            //             }
            //         }, 3000);
            //     }, 3000);
            // }
        },
        autoAppear() {
            const tooltip = document.getElementById(this.id);
            if (tooltip.classList.contains('hiding')) {
                tooltip.classList.remove('hiding');
            }
            this.isHiding = false;
        },
    },
    mounted() {
        if (this.coordinates) {
            this.$refs.container.classList.add('fixed');
            const tooltip = document.getElementById(this.id);
            const boundingClientRect = tooltip.getBoundingClientRect();
            tooltip.style.top = this.coordinates.top + 'px';
            switch (this.posType) {
                case 'center':
                    tooltip.style.left = this.coordinates.left - boundingClientRect.width / 2 + 'px';
                    break;
                case 'left':
                    tooltip.style.left = this.coordinates.left - boundingClientRect.width + 'px';
                    break;
                case 'right':
                    tooltip.style.left = this.coordinates.left + 'px';
                    break;
                default:
                    tooltip.style.left = this.coordinates.left + 'px';
            }
        }

        this.autoClose();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>



.tooltip-container
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 3
    .tooltip
        position: absolute
        background-color: #FFF
        box-shadow: 0 0 8px rgba(33, 33, 33, .1)

.hiding
    animation-duration: 3s
    animation-name: hiding

@keyframes hiding
    from
        opacity: 1
    to
        opacity: 0

</style>
