<template>
    <div class="admin-panel">
        <div class="admin-panel__header">
            <h1>Настройки дашборда</h1>
            <div class="admin-options">
                <div class="control flex">
                    <div class="label-container">
                        <label>Цели Комплекса</label>
                    </div>
                    <small-btn-container :icon="'dashboard'" @click="$router.push({ name: 'StrategicDashboard' })"/>
                </div>
            </div>
        </div>
        <div class="admin-panel__content">
            <div class="tab-container">
                <div class="tab-list">
                    <template v-for="tab in tabs">
                        <div class="tab" :class="{ selected: selectedTab.title === tab.title }" @click="selectTab(tab)">{{ tab.title }}</div>
                    </template>
                </div>
                <div class="tab-content">
                    <user-role v-if="selectedTab.code === 'roles'"/>
                    <indication v-if="selectedTab.code === 'indications'"/>
                    <extra v-if="selectedTab.code === 'extra'"/>
                    <import v-if="selectedTab.code === 'import'"/>
                    <email-notification v-if="selectedTab.code === 'email_notification'"/>
                    <admin-oiv v-if="selectedTab.code === 'oiv'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import SmallBtnContainer from '../components/ReusableComponents/SmallBtnContainer.vue';
import UserRole from '../components/Admin/UserRole.vue';
import Indication from '../components/Admin/Indication.vue';
import Extra from '../components/Admin/Extra.vue';
import Import from '../components/Admin/Import/Index.vue';
import EmailNotification from '../components/Admin/EmailNotification.vue';
import AdminOiv from '../components/Admin/Oiv/Index.vue';

export default {
    name: 'AdminDashboard',
    components: {
        SmallBtnContainer,
        UserRole,
        Indication,
        Extra,
        Import,
        EmailNotification,
        AdminOiv,
    },
    data() {
        return {
            tabs: [
                {
                    title: 'Управление ролями',
                    code: 'roles',
                },
                {
                    title: 'Управление ОИВ',
                    code: 'oiv',
                },
                {
                    title: 'Управление индикацией',
                    code: 'indications',
                },
                {
                    title: 'Импорт показателей',
                    code: 'import',
                },
                {
                    title: 'Email-уведомления',
                    code: 'email_notification',
                },
                {
                    title: 'Дополнительные настройки',
                    code: 'extra',
                },
            ],
            selectedTab: null,
        }
    },
    computed: {
        ...mapGetters([

        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'init',
        ]),
        selectTab(tab) {
            this.selectedTab = tab;
        }
    },
    created() {
        this.selectedTab = this.tabs[0];
    },
    mounted() {
        this.init();
    }
}
</script>

<style lang="sass">
@import '../assets/index.sass'

.admin-panel
    .admin-panel__header
        position: relative
        margin-bottom: 25px
        h1
            font-size: 24px
            text-align: left
            line-height: 32px
        .admin-options
            position: absolute
            top: 0
            right: 0

.control.flex
    display: flex
    .label-container
        flex-direction: column
        justify-content: center
        margin: 0
        label
            padding: 0 4px
            font-weight: 400
            font-size: 14px

.tab-container
    .tab-list
        display: flex
        border-bottom: 2px solid $brand-primary-color
        background-color: $gray-50-color
        .tab
            padding: 11px 20px
            font-size: 14px
            color: $gray-700-color
            background-color: $gray-100-color
            cursor: pointer
            &.selected
                color: #fff
                background-color: $brand-primary-color
</style>
