import {
    createRouter,
    createWebHashHistory,
    createWebHistory
} from 'vue-router'

import StrategicDashboard from '../views/StrategicDashboard'
import AdminDashboard from '../views/AdminDashboard'
import Greetings from '../views/Greetings'

const routes = [
    {
        path: '/',
        name: 'Greetings',
        component: Greetings,
    },
    {
        path: '/dashboard',
        name: 'StrategicDashboard',
        component: StrategicDashboard,
    },
    {
        path: '/admin',
        name: 'AdminDashboard',
        component: AdminDashboard,
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: function () {
    //         return import(/* webpackChunkName: "about" */ '../views/About.vue')
    //     }
    // }
]

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
})

export default router
