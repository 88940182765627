<template>
    <div class="user-role-edit">
        <div class="section">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <input type="text" v-model="roleName"/>
            </div>
        </div>
        <div class="section">
            <div class="label-container">
                <span>Назначенные сотрудники</span>
            </div>
            <template v-if="isAddEmployeeVisible">
                <div class="input-container search">
                    <img src="~@/index/assets/icons/search.svg"/>
                    <input type="text" placeholder="Поиск" v-model="searchEmployee" ref="search"/>
                </div>
                <div class="search-result">
                    <ul class="tooltip-indicator-list custom-scrollbar">
                        <li v-for="searchedEmployee in searchedEmployees" @click="selectEmployee(searchedEmployee)">
                            <div class="control">
                                <div class="checkbox-container">
                                    <input type="checkbox" :checked="selectedEmployees.some(e => e.employee_id === searchedEmployee.employee_id)"/>
                                    <label>
                                        <span>{{ searchedEmployee.name }}</span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <ul class="user-notes">
                                    <li>
                                        <span>Отдел:</span>
                                        <span>{{ searchedEmployee.department }}</span>
                                    </li>
                                    <li>
                                        <span>E-почта:</span>
                                        <span>{{ searchedEmployee.email }}</span>
                                    </li>
                                    <li>
                                        <span>Должность:</span>
                                        <span>{{ searchedEmployee.position }}</span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btn-group">
                    <btn-container :small="true" :title="'Отмена'" @click.stop="isAddEmployeeVisible = false"/>
                    <btn-container :primary="true" :small="true" :title="'Добавить'" @click.stop="addEmployees"/>
                </div>
            </template>
            <template v-else>
                <table v-if="roleUsers.length">
                    <tbody>
                    <template v-for="employee in roleUsers">
                        <tr>
                            <template v-if="employee.name">
                                <td>{{ employee.name }}</td>
                            </template>
                            <td>{{ employee.department }}</td>
                            <td>
                                <div class="control-list">
                                    <div class="control">
                                        <span class="material-icons-outlined" @click="removeEmployee(employee)">delete</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                <btn-container
                        :primary="true"
                        :small="true"
                        :title="'Добавить сотрудника'"
                        :icon="'add'"
                        @click="isAddEmployeeVisible = true"/>
            </template>
        </div>
        <div class="section">
            <div class="label-container">
                <span>Доступы</span>
            </div>
            <template v-if="isAddOperationVisible">
                <ul class="tooltip-indicator-list custom-scrollbar">
                    <li>
                        <div class="control">
                            <div class="checkbox-container" @click="toggleAllOperations">
                                <input type="checkbox" :checked="selectedOperations.length === operations.length"/>
                                <label>Все доступы</label>
                            </div>
                        </div>
                    </li>
                    <li v-for="operation in operations">
                        <div class="control">
                            <div class="checkbox-container" @click="toggleOperation(operation)">
                                <input type="checkbox" :checked="selectedOperations.some(o => o.id === operation.id)"/>
                                <label>{{ operation.title }}</label>
                            </div>

                        </div>
                    </li>
                </ul>
                <div class="btn-group">
                    <btn-container :small="true" :title="'Отмена'" @click.stop="isAddOperationVisible = false"/>
                    <btn-container :primary="true" :small="true" :title="'Добавить'" @click.stop="addOperations"/>
                </div>
            </template>
            <template v-else>
                <table v-if="roleOperations.length">
                    <tbody>
                    <template v-for="operation in roleOperations">
                        <tr :class="`operation-${operation.id}`">
                            <td>{{ operation.title }}</td>
                            <td>
                                <div class="control-list">
                                    <div class="control" v-if="operation.hasDuration">
                                        <span
                                            class="material-icons"
                                            @click="showOptions(operation)">schedule</span>
                                    </div>
                                    <div class="control">
                                        <span class="material-icons-outlined" @click="removeOperation(operation)">delete</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <template v-if="operation.hasDuration">
                            <tr class="options" :class="`operation-${operation.id}`">
                                <td colspan="3">
                                    <div class="label-container">
                                        <span>Интервалы</span>
                                    </div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Наименование</th>
                                            <th>Период</th>
                                            <th>Длительность</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="interval in operation.intervals">
                                            <tr>
                                                <td>{{ interval.title }}</td>
                                                <td>{{ calcIntervalPeriod(interval.config) }}</td>
                                                <td></td>
                                                <td>
                                                    <div class="control-list">
                                                        <div v-if="interval.id" class="control">
                                                            <img class="frame"
                                                                 src="~@/index/assets/icons/frame.svg"
                                                                 @click="showIntervalForm(operation, interval)"
                                                            />
                                                        </div>
                                                        <div class="control">
                                                            <span class="material-icons-outlined" @click="removeRoleInterval(operation, interval)">delete</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                        </tbody>
                                    </table>
                                    <btn-container
                                        :primary="true"
                                        :small="true"
                                        :title="'Добавить интервал'"
                                        @click.stop="showIntervalForm(operation)"/>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
                <btn-container
                    :primary="true"
                    :small="true"
                    :title="'Добавить доступ'"
                    :icon="'add'"
                    @click="isAddOperationVisible = true"/>
            </template>
        </div>
        <div class="section">
            <div class="label-container">
                <span>ОИВ</span>
            </div>
            <template v-if="isAddOivVisible">
                <ul class="tooltip-indicator-list custom-scrollbar">
                    <li>
                        <div class="control">
                            <div class="checkbox-container" @click="toggleAllOivs">
                                <input type="checkbox" :checked="selectedOivs.length === oivs.length"/>
                                <label>Все ОИВ</label>
                            </div>
                        </div>
                    </li>
                    <li v-for="oiv in oivs">
                        <div class="control">
                            <div class="checkbox-container" @click="toggleOiv(oiv)">
                                <input type="checkbox" :checked="selectedOivs.some(o => o.id === oiv.id)"/>
                                <label>{{ oiv.title }}</label>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="btn-group">
                    <btn-container :small="true" :title="'Отмена'" @click.stop="isAddOivVisible = false"/>
                    <btn-container :primary="true" :small="true" :title="'Добавить'" @click.stop="addOivs"/>
                </div>
            </template>
            <template v-else>
                <table v-if="roleOivs.length">
                    <tbody>
                    <template v-for="oiv in roleOivs">
                        <tr>
                            <td>{{ oiv.title }}</td>
                            <td>
                                <div class="control-list">
                                    <div class="control">
                                        <span class="material-icons-outlined" @click="removeOiv(oiv)">delete</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
                <btn-container
                    :primary="true"
                    :small="true"
                    :title="'Добавить ОИВ'"
                    :icon="'add'"
                    @click="isAddOivVisible = true"/>
            </template>
        </div>
        <div class="section">
            <div class="btn-group">
                <btn-container :small="true" :title="'Отмена'" @click.stop="$emit('close')"/>
                <btn-container :primary="true" :small="true" :title="'Сохранить изменения'" @click.stop="save"/>
            </div>
        </div>
        <datepicker-modal v-if="isDatepickerModalVisible" @save="addInterval" @close="isDatepickerModalVisible = false"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import DatepickerModal from '@/index/components/Admin/UserRole/DatepickerModal.vue';
import dateformat from 'dateformat';
import { i18n } from "dateformat";
i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
i18n.monthNames = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

export default {
    name: 'UserRoleEdit',
    components: {
        BtnContainer,
        DatepickerModal,
    },
    data() {
        return {
            roleName: '',
            roleUsers: [],
            roleOperations: [],
            roleOivs: [],
            selectedEmployees: [],
            searchEmployee: '',
            searchedEmployees: [],
            selectedOperations: [],
            selectedOivs: [],
            isAddEmployeeVisible: false,
            isAddOperationVisible: false,
            isAddOivVisible: false,
            isDatepickerModalVisible: false,
            selectedRoleOperation: null,
            selectedRole: null,
            selectedInterval: null,
            checkEmployeeAdmit: false,
        }
    },
    computed: {
        ...mapGetters([
            'operations',
            'oivs',
            'employees',
        ]),
    },
    watch: {
        searchEmployee(v) {
            if (v && v.length > 2) {
                this.searchedEmployees = this.employees.filter(employee => {
                    if (employee.department && employee.department.toLowerCase().includes(v.toLowerCase())) {
                        return true;
                    }
                    if (employee.name.toLowerCase().includes(v.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
            } else {
                this.searchedEmployees = [];
            }
        },
        isAddOperationVisible(v) {
            if (!v) {
                this.selectedOperations = this.roleOperations;
            }
        },
        isAddEmployeeVisible(v) {
            if (!v) {
                this.selectedEmployees = this.roleUsers;
            }
        },
        isAddOivVisible(v) {
            if (!v) {
                this.selectedOivs = this.roleOivs;
            }
        },
        // selectedEmployees() {
        //     console.log('selected employee', this.searchedEmployees);
        //     if (this.selectedEmployees.length === 0) {
        //         this.checkEmployeeAdmit = false;
        //     } else if (this.selectedEmployees.length === this.roleUsers.length) {
        //         this.checkEmployeeAdmit = false;
        //     } else {
        //         const compare = this.selectedEmployees.filter(se => this.roleUsers.some(ru => ru.employee_id === se.employee_id));
        //         this.checkEmployeeAdmit = !!compare.length;
        //     }
        // }
    },
    methods: {
        ...mapActions([
            'createRole',
            'updateRole',
            'removeInterval',
        ]),
        selectEmployee(employee) {
            if (this.selectedEmployees.some(e => e.employee_id === employee.employee_id)) {
                this.selectedEmployees = this.selectedEmployees.filter(e => e.employee_id !== employee.employee_id);
            } else {
                this.selectedEmployees = this.selectedEmployees.concat(employee);
            }
        },
        addEmployees() {
            // if (this.checkEmployeeAdmit) {
                this.roleUsers = this.selectedEmployees;
                this.isAddEmployeeVisible = false;
            // }
        },
        toggleAllOperations() {
            if (this.selectedOperations.length < this.operations.length) {
                this.selectedOperations = this.operations;
            } else {
                this.selectedOperations = [];
            }
        },
        toggleOperation(op) {
            if (this.selectedOperations.some(o => o.id === op.id)) {
                this.selectedOperations = this.selectedOperations.filter(o => o.id !== op.id);
            } else {
                this.selectedOperations = this.selectedOperations.concat(op);
            }
        },
        addOperations() {
            this.roleOperations = this.selectedOperations;
            this.isAddOperationVisible = false;
        },
        toggleAllOivs() {
            if (this.selectedOivs.length < this.oivs.length) {
                this.selectedOivs = this.oivs;
            } else {
                this.selectedOivs = [];
            }
        },
        toggleOiv(oiv) {
            if (this.selectedOivs.some(o => o.id === oiv.id)) {
                this.selectedOivs = this.selectedOivs.filter(o => o.id !== oiv.id);
            } else {
                this.selectedOivs = this.selectedOivs.concat(oiv);
            }
        },
        addOivs() {
            this.roleOivs = this.selectedOivs;
            this.isAddOivVisible = false;
        },
        removeEmployee(employee) {
            this.roleUsers = this.roleUsers.filter(e => e.employee_id !== employee.employee_id);
        },
        removeOperation(operation) {
            this.roleOperations = this.roleOperations.filter(o => o.id !== operation.id);
            this.selectedOperations = this.selectedOperations.filter(o => o.id !== operation.id);
        },
        removeOiv(oiv) {
            this.roleOivs = this.roleOivs.filter(roleOiv => roleOiv.id !== oiv.id);
        },
        showOptions(data) {
            const className = `operation-${data.id}`;
            const trList = document.getElementsByClassName(className);
            if (trList[1].style.display === 'table-row') {
                trList[1].style.display = 'none';
            } else if (trList.length > 1) {
                trList[1].style.display = 'table-row';
            }
        },
        showIntervalForm(operation, interval = null) {
            this.selectedRoleOperation = operation;
            this.selectedInterval = interval;
            this.isDatepickerModalVisible = true;
        },
        calcIntervalPeriod(config) {
            return `${dateformat(new Date(config.from), 'd mmm')} - ${dateformat(new Date(config.to), 'd mmm')}`;
        },
        addInterval(interval) {
            if (interval) {
                if (!this.selectedRoleOperation.intervals) {
                    this.selectedRoleOperation.intervals = [];
                }
                if (interval.id) {
                    this.selectedInterval.title = interval.title;
                    this.selectedInterval.config = interval.config;
                    this.selectedInterval.external_type = interval.external_type;
                    this.selectedInterval.external_link_id = interval.external_link_id;
                } else {
                    this.selectedRoleOperation.intervals.push(interval);
                }
            }
            this.isDatepickerModalVisible = false;
        },
        removeRoleInterval(operation, interval) {
            operation.intervals = operation.intervals.filter(i => i.id !==interval.id);
        },
        save() {
            if (this.roleName && this.roleUsers.length && this.roleOperations.length) {
                const role = {
                    title: this.roleName,
                    employees: this.roleUsers,
                    operations: this.roleOperations,
                    oivs: this.roleOivs,
                };
                if (this.$parent.selectedRole) {
                    role.id = this.$parent.selectedRole.id;
                }
                this.$store.commit('setRole', role);
                this.$parent.selectedRole ? this.updateRole() : this.createRole();
                this.$emit('close');
            }
        }
    },
    created() {
        if (this.$parent.selectedRole) {
            this.selectedRole = this.$parent.selectedRole;
            this.roleName = this.$parent.selectedRole.title;
            this.roleUsers = this.$parent.selectedRole.users;
            this.selectedEmployees = this.roleUsers;
            this.roleOperations = this.$parent.selectedRole.operations;
            this.selectedOperations = this.roleOperations;
            this.roleOivs = this.$parent.selectedRole.oivs;
            this.selectedOivs = this.roleOivs;
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.user-role-edit
    max-width: 700px
    .section
        position: relative
        padding: 0 0 0 20px
        margin-bottom: 20px
        &:before
            content: ''
            position: absolute
            left: 0
            display: inline-block
            height: 100%
            border-left: 2px solid $brand-primary-color
        &:last-child:before
            border: none
        table
            margin-bottom: 10px

h1, h2
    margin: 0
    font-size: 18px

table
    border-collapse: collapse
    width: 100%
    th
        padding: 10px 20px
        font-size: 12px
        font-weight: normal
        text-align: left
    td
        padding: 10px 20px
        font-size: 12px
        border-bottom: 2px solid #fff
        background-color: $blue-light
        
.control-list
    display: flex
    justify-content: flex-end
    .control
        display: flex
        margin-right: 4px
        span
            font-size: 18px

.material-icons-outlined
    font-size: 18px
    &.not-saved
        color: $gray-500-color

.btn-container
    width: 150px

.btn-group
    .btn-container
        &:first-child
            margin-right: 15px

.tooltip-indicator-list
    max-height: 300px
    margin: 4px 2px 4px 0
    padding: 20px 0 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    li
        min-width: 220px
        margin-right: 15px
        padding: 12px 0
        border-bottom: 1px solid $gray-200-color
        &:first-child
            padding-top: 0
        &:last-child
            border-bottom: none

.user-notes
    list-style-type: none
    margin: 0 0 0 25px
    padding: 0
    li
        padding: 0
        font-size: 12px
        border: none
        span
            color: $gray-600-color
        span:first-child
            margin-right: 6px

.options
    display: none
    > td
        padding-left: 40px
        padding-right: 0
        background-color: #fff

</style>
