<template>
    <modal :title="modalTitle" @save="update">
        <div class="control">
            <div class="label-container">
                <span>Направление</span>
            </div>
            <select-direction @select="selectDirection" :value="direction"/>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" v-model="groupTitle"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import SelectDirection from '../components/SelectDirection.vue';

export default {
    name: 'EditGroupModal',
    components: {
        Modal,
        SelectDirection,
    },
    data() {
        return {
            modalTitle: 'Редактировать группу',
            groupTitle: '',
            direction: null,
        }
    },
    computed: {
        ...mapGetters([
            'data',
        ]),
    },
    methods: {
        ...mapActions([
            'updateGroup',
        ]),
        selectDirection(direction) {
            this.direction = direction;
        },
        update() {
            if (this.groupTitle && this.direction && this.groupTitle !== this.$parent.selectedIndicator.title) {
                this.$store.commit('setGroup', {
                    id: this.$parent.selectedIndicator.id,
                    title: this.groupTitle,
                    parent_id: this.direction.id
                });
                this.updateGroup();
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.groupTitle = this.$parent.selectedIndicator.title;
        this.direction = this.data.find(i => i.id === this.$parent.selectedIndicator.parent_id);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 40px
    &:last-child
        margin-bottom: 0
    span
        font-weight: 600
        font-size: $font-size
    .input-container
        margin-top: 16px

</style>
