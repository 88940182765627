<template>
    <div class="tooltip-container" @click.self="$emit('close')">
        <div :id="id" class="tooltip-filter">
            <div class="tooltip-content">
                <header v-if="withHeader">
                    <div class="tooltip-header">
                        <slot name="header"></slot>
                    </div>
                </header>
                <body>
                <div class="tooltip-body">
                    <slot></slot>
                </div>
                </body>
                <footer v-if="withFooter">
                    <div class="tooltip-footer">
                        <slot name="footer">
                            <div class="btn-group">
                                <btn-container :small="true" :title="'Отмена'" @click.stop="$emit('close')"/>
                                <btn-container :primary="true" :small="true" :title="'Применить'" @click="$emit('apply')"/>
                            </div>
                        </slot>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';

export default {
    name: 'TooltipFilter',
    props: ['coordinates', 'id', 'withHeader', 'withFooter'],
    components: {
        BtnContainer,
    },
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {
        if (this.coordinates) {
            const tooltip = document.getElementById(this.id);
            const boundingClientRect = tooltip.getBoundingClientRect();
            tooltip.style.top = this.coordinates.top + 'px';
            // tooltip.style.left = this.coordinates.left - boundingClientRect.width + 'px';
            tooltip.style.left = this.coordinates.left + 'px';
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.tooltip-container
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    z-index: 3

.tooltip-filter
    position: absolute
    background-color: #FFF
    box-shadow: 0 0 8px rgba(33, 33, 33, .1)
    z-index: 3
    .tooltip-header
        padding: 20px 20px 0 20px
    .tooltip-body
        padding: 0 2px 0 20px
        border-bottom: 1px solid $gray-200-color
    .tooltip-footer
        padding: 20px

.btn-group
    display: flex
    .btn-container
        flex-grow: 1
        &:first-child
            margin-right: 15px

</style>
