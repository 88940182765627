<template>
    <delete-modal :title="title" @remove="remove">
        <div class="info">
            При удалении все показатели будут удалены без возможности восстановления.
            <br/>
            Вы уверены, что хотите удалить цель?
        </div>
    </delete-modal>
</template>

<script>
import { mapActions } from 'vuex';
import DeleteModal from '../components/ReusableComponents/DeleteModal.vue'

export default {
    name: 'DeleteTargetModal',
    components: {
        DeleteModal,
    },
    data() {
        return {
            title: 'Удалить цель',
        }
    },
    methods: {
        ...mapActions([
            'removeTarget',
        ]),
        remove() {
            this.$store.commit('setTarget', { id: this.$parent.selectedIndicator.id });
            this.removeTarget();
            this.$emit('close');
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.info
    color: $gray-900-color !important
    font-size: 12px
    font-weight: 500
</style>
