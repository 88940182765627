class Calculation {
    calcPercent(planValue, factValue, calculationType) {
        if (!planValue || planValue === 0) return '';
        if (!factValue || factValue === 0) return '';
        if (isNaN(parseFloat(planValue))) return '';
        if (isNaN(parseFloat(factValue))) return '';
        const result = this.calc(calculationType, parseFloat(planValue), parseFloat(factValue));
        return isNaN(result) ? '' : result;
    }
    calc(calculationType, planValue, factValue) {
        switch (calculationType) {
            // case 'numLprop':
            case 'Численный, "Больше-лучше", пропорциональный':
                return this.numLprop(planValue, factValue);
            // case 'numMprop':
            case 'Численный, "Меньше-лучше", пропорциональный':
                return this.numMprop(planValue, factValue);
            // case 'numLdisc':
            case 'Численный, "Больше-лучше", дискретный':
                return this.numLdisc(planValue, factValue);
            // case 'numMdisc':
            case 'Численный, "Меньше-лучше", дискретный':
                return this.numMdisc(planValue, factValue);
        }
    }
    numLprop(p, f) {
        return (f / p) * 100;
    }
    numMprop(p, f) {
        return (( p - f ) / p) * 100;
    }
    numLdisc(p, f) {
        return f >= p ? 100 : 0;
    }
    numMdisc(p, f) {
        return ( f <= p ) && ( f > 0 ) ? 100 : 0;
    }
    calcSum(calculationType, quarterValues) {
        switch (calculationType) {
            // case 'progress':
            case 'Последний (нарастающий итог)':
                return this.progress(quarterValues);
            // case 'sum':
            case 'Сумма кварталов':
                return this.sum(quarterValues);
            // case 'one':
            case 'Достаточно одного (лучший процент)':
                return this.one(quarterValues);
        }
    }
    progress(values) {
        return values.pop();
    }
    sum(values) {
        return values.reduce((a, b) => a + b);
    }
    one(values) {
        return values.reduce((a, b) => a || b);
    }
    checkInterval(from, to, date = new Date) {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        let monthCheck = null;
        if (dateFrom.getMonth() > dateTo.getMonth()) {
            if (date.getMonth() >= dateFrom.getMonth()) monthCheck = true;
            if (date.getMonth() <= dateTo.getMonth()) monthCheck = false;
        } else {
            monthCheck = (date.getMonth() >= dateFrom.getMonth() && date.getMonth() <= dateTo.getMonth());
        }
        let dayCheck = null;
        if (dateFrom.getDate() > dateTo.getDate()) {
            if (date.getDate() >= dateFrom.getDate()) dayCheck = true;
            if (date.getDate() <= dateTo.getDate()) dayCheck = false;
        } else {
            dayCheck = (date.getDate() >= dateFrom.getDate() && date.getDate() <= dateTo.getDate());
        }

        return monthCheck && dayCheck;
    }
}

export default new Calculation;