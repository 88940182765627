<template>
    <router-view/>
</template>

<style lang="sass">
@import './assets/index.sass'

html, body
    margin: 0
    padding: 0
    *
        font-family: $font-family
        color: $gray-900-color
        -webkit-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none
#app
    padding: 10px 35px
    font-family: Avenir, Helvetica, Arial, sans-serif

</style>
