<template>
    <modal :title="modalTitle" @save="create">
        <div class="control">
            <div class="label-container">
                <span>Цель</span>
            </div>
            <select-target @select="selectTarget" :value="target"/>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" v-model="indicatorTitle"></textarea>
            </div>
        </div>
        <div class="control">
            <div class="label-container">
                <span>ОИВ</span>
            </div>
            <select-oiv @select="selectOivs" :value="oivs"/>
        </div>
        <div class="control select-group-container">
            <div class="select-group">
                <div class="label-container">
                    <span>Тип</span>
                </div>
                <select-indicator-value-type @select="selectValueType" :value="valueType"/>
            </div>
            <div class="select-group">
                <div class="label-container">
                    <span>Единица измерения</span>
                </div>
                <select-unit-measure @select="selectUnitMeasure" :value="unitMeasure"/>
            </div>
        </div>
        <div class="control select-group-container">
            <div class="select-group">
                <div class="label-container">
                    <span>Расчет процента достижения</span>
                </div>
                <select-indicator-calculation-type @select="selectCalculationType" :value="calculationType"/>
            </div>
            <div class="select-group">
                <div class="label-container">
                    <span>Расчет суммы за год</span>
                </div>
                <select-indicator-sum-calculation-type @select="selectSumCalculationType" :value="sumCalculationType"/>
            </div>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Методика расчета</span>
            </div>
            <div class="text-container">
                <label>Введите комментарий</label>
                <textarea class="custom-scrollbar" v-model="comment"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '../components/ReusableComponents/BtnContainer.vue';
import SelectContainer from '../components/ReusableComponents/SelectContainer.vue';
import Modal from '../components/ReusableComponents/Modal.vue';
import SelectTarget from '../components/SelectTarget.vue';
import SelectUnitMeasure from '../components/SelectUnitMeasure.vue';
import SelectIndicatorValueType from '../components/SelectIndicatorValueType.vue';
import SelectOiv from '../components/SelectOiv.vue';
import SelectIndicatorCalculationType from '../components/SelectIndicatorCalculationType.vue';
import SelectIndicatorSumCalculationType from '../components/SelectIndicatorSumCalculationType.vue';

export default {
    name: 'CreateIndicatorModal',
    components: {
        BtnContainer,
        SelectContainer,
        Modal,
        SelectTarget,
        SelectOiv,
        SelectUnitMeasure,
        SelectIndicatorValueType,
        SelectIndicatorCalculationType,
        SelectIndicatorSumCalculationType,
    },
    data() {
        return {
            modalTitle: 'Редактировать показатель',
            indicatorTitle: '',
            target: null,
            oivs: [],
            unitMeasure: null,
            valueType: '',
            calculationType: '',
            sumCalculationType: '',
            comment: '',
        }
    },
    computed: {
        ...mapGetters([
            'data',
            'unitMeasures',
        ]),
    },
    methods: {
        ...mapActions([
            'updateIndicator',
        ]),
        selectTarget(target) {
            this.target = target;
        },
        selectOivs(oivs) {
            this.oivs = oivs;
        },
        selectUnitMeasure(unitMeasure) {
            this.unitMeasure = unitMeasure;
        },
        selectValueType(valueType) {
            this.valueType = valueType;
        },
        selectCalculationType(calculationType) {
            this.calculationType = calculationType;
        },
        selectSumCalculationType(calculationType) {
            this.sumCalculationType = calculationType;
        },
        proceed() {
            return this.indicatorTitle
                && this.target
                && this.oivs.length
                && this.valueType
                && this.calculationType
                && this.sumCalculationType;
        },
        create() {
            if (this.proceed()) {
                this.$store.commit('setIndicator', {
                    id: this.$parent.selectedIndicator.id,
                    title: this.indicatorTitle,
                    parent_id: this.target.id,
                    oivs: this.oivs.map(o => o.id),
                    unit_measure_id: this.unitMeasure ? this.unitMeasure.id : null,
                    value_type: this.valueType,
                    calculation_type: this.calculationType,
                    sum_calculation_type: this.sumCalculationType,
                    comment: this.comment,
                });
                this.updateIndicator();
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.indicatorTitle = this.$parent.selectedIndicator.title;
        this.target = this.data.find(i => i.id === this.$parent.selectedIndicator.parent_id);
        this.oivs = this.$parent.selectedIndicator.oivs;
        this.valueType = this.$parent.selectedIndicator.value_type;
        this.calculationType = this.$parent.selectedIndicator.calculation_type;
        this.unitMeasure = this.unitMeasures.find(u => u.id === this.$parent.selectedIndicator.unit_measure_id);
        this.sumCalculationType = this.$parent.selectedIndicator.sum_calculation_type;
        this.comment = this.$parent.selectedIndicator.comment;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 20px
    &:last-child
        margin-bottom: 0
    span
        font-weight: 600
        font-size: $font-size
    .input-container
        margin-top: 16px

.select-group-container
    display: flex
    .select-group
        flex-grow: 1
        &:first-child
            margin-right: 15px
</style>
