<template>
    <div class="select-container" ref="container">
        <div class="input-container" @click="toggleTooltip">
            <label>Выберите из списка</label>
            <input type="text" v-model="calculatedValue"/>
            <div class="control-elements">
                <div class="arrows">
                    <div>
                        <template v-if="isOptionsContainerVisible">
                            <img src="~@/index/assets/icons/chevron_up.svg"/>
                        </template>
                        <template v-else>
                            <img src="~@/index/assets/icons/chevron_down.svg"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="tooltipCoordinates">
            <tooltip-filter
                :id="id"
                :coordinates="tooltipCoordinates"
                :with-header="true"
                :with-footer="true"
                @apply="apply"
                @close="cancel">
                <ul class="tooltip-indicator-list custom-scrollbar">
                    <li v-for="compareType in compareTypes">
                        <div class="control">
                            <div class="radio-container" @click="toggle(compareType)">
                                <input type="radio" name="compare-type" :checked="compareTypeSelected.compare === compareType.compare"/>
                                <label v-html="compareType.title"></label>
                            </div>
                        </div>
                    </li>
                </ul>
            </tooltip-filter>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '@/index/components/ReusableComponents/TooltipFilter.vue';

export default {
    props: ['compare'],
    components: {
        TooltipFilter,
    },
    data() {
        return {
            isOptionsContainerVisible: false,
            tooltipCoordinates: null,
            id: 'tooltip-select-compare-options',
            calculatedValue: '',
            compareTypes: [
                {
                    compare: 'gt',
                    title: '>',
                },
                {
                    compare: 'ge',
                    title: '>=',
                },
                {
                    compare: 'eq',
                    title: '=',
                },
                {
                    compare: 'lt',
                    title: '<',
                },
                {
                    compare: 'le',
                    title: '<=',
                },
            ],
            compareTypeSelected: '',
        }
    },
    watch: {
        compareTypeSelected() {
            this.calculateValue();
        }
    },
    methods: {
        toggleTooltip() {
            if (this.isOptionsContainerVisible) {
                this.tooltipCoordinates = null;
            } else {
                const parentPos = this.$refs.container.getBoundingClientRect();
                this.tooltipCoordinates = {
                    top: parentPos.bottom,
                    left: parentPos.left
                };
            }
            this.isOptionsContainerVisible = !this.isOptionsContainerVisible;
        },
        toggle(compareType) {
            this.compareTypeSelected = compareType;
        },
        apply() {
            this.calculateValue();
            this.$emit('select', this.compareTypeSelected);
            this.isOptionsContainerVisible = false;
            this.tooltipCoordinates = null;
        },
        calculateValue() {
            this.calculatedValue = this.compareTypeSelected.title;
        },
        cancel() {
            this.isOptionsContainerVisible = false;
            this.tooltipCoordinates = null;
        }
    },
    created() {
        this.compareTypeSelected = this.compareTypes.find(c => c.compare === this.compare);
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.tooltip-indicator-list
    min-width: 200px
    max-height: 300px
    margin: 20px 2px 20px 0
    padding: 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    li
        margin-right: 15px
        padding: 15px 0
        border-bottom: 1px solid $gray-200-color
        &:first-child
            padding-top: 0
        &:last-child
            padding-bottom: 0
            border-bottom: none
        .control
            display: flex
            label
                max-width: 260px

</style>
