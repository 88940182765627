<template>
    <modal :title="modalTitle" @save="save" @close="$emit('cancel')">
        <div class="control">
            <div class="input-container">
                <!--<label>Предыдущее значение</label>-->
                <input type="text" v-model="emailSubject"/>
            </div>
        </div>
        <div class="control">
            <div class="text-container">
                <textarea class="custom-scrollbar" v-model="emailMessage"></textarea>
            </div>
        </div>
        <template v-if="emailDates">
            <div class="control flex" v-for="(date, index) in emailDates" :key="index">
                <div class="text-container">
                    <flat-pickr v-model="emailDates[index]" :config="config"></flat-pickr>
                </div>
                <span class="material-icons-outlined" @click="removeDate(index)">delete</span>
            </div>
            <btn-container :primary="true" :small="true" :title="'Добавить дату'" @click="addDate"/>
        </template>
    </modal>
</template>

<script>
import Modal from '@/index/components/ReusableComponents/Modal.vue';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import FlatPickr from 'vue-flatpickr-component';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/flatpickr.css';

export default {
    components: {
        Modal,
        FlatPickr,
        BtnContainer,
    },
    props: ['notification'],
    data() {
        return {
            modalTitle: '',
            emailSubject: null,
            emailMessage: null,
            emailDates: null,
            config: {
                locale: Russian,
                enableTime: true,
            },
            dates: [],
        }
    },
    methods: {
        addDate() {
            this.emailDates.push(new Date());
        },
        removeDate(index) {
            this.emailDates = this.emailDates.filter((date, i) => i !== index);
        },
        save() {
            const data = {
                subject: this.emailSubject,
                message: this.emailMessage
            };
            if (this.emailDates) {
                data.dates = this.emailDates;
            }
            this.$emit('update', data);
        }
    },
    created() {
        this.modalTitle = this.notification.title;
        this.emailSubject = this.notification.config.subject;
        this.emailMessage = this.notification.config.message;
        this.emailDates = this.notification.config.dates || null;
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.btn-container
    max-width: 120px

.control
    margin-bottom: 10px
    span
        display: flex
        flex-direction: column
        justify-content: center
        font-size: 18px

.control.flex
    display: flex
    .text-container
        flex-grow: 1
    span
        margin: 0 6px

.input-container
    input
        padding: 15px 20px

.text-container
    textarea
        padding: 10px
        height: 200px

</style>
