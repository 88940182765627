<template>
    <div class="btn-container" :class="{ 'btn-primary': primary, 'btn-small': small }">
        <div class="title-container">
            <div>
                <div>
                    <div v-if="icon">
                        <template v-if="icon === 'export'">
                            <img class="icon" src="~@/index/assets/icons/export.svg"/>
                        </template>
                        <template v-if="icon === 'save_filter'">
                            <img class="icon" src="~@/index/assets/icons/save_filter.svg"/>
                        </template>
                        <template v-if="icon === 'add'">
                            <!--<span class="material-icons">add</span>-->
                        </template>
                    </div>
                    <div>
                        <span class="title">{{ title }}</span>
                    </div>
                </div>
            </div>
        </div>
        <input type="button"/>
    </div>
</template>

<script>

export default {
    name: 'BtnContainer',
    props: ['primary', 'small', 'icon', 'title'],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.material-icons
    line-height: 1
</style>
