<template>
    <div class="user-role-management">
        <div class="header">
            <btn-container
                v-if="isEditFormVisible === false"
                :primary="true"
                :small="true"
                :title="'Добавить роль'"
                :icon="'add'"
                @click="showEditForm(null)"
            />
        </div>
        <div class="content">
            <template v-if="isEditFormVisible">
                <user-role-edit @close="isEditFormVisible = false"/>
            </template>
            <template v-else>
                <h3>Роли</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Название роли</th>
                        <th>Доступы</th>
                        <th>Сотрудники</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="role in roles">
                        <tr>
                            <td>{{ role.title }}</td>
                            <td>{{ role.operations.map(o => o.title).join(', ') }}</td>
                            <td>{{ role.users.map(e => e.name).join(', ') }}</td>
                            <td>
                                <div class="control-list">
                                    <div class="control">
                                        <img class="frame"
                                             src="~@/index/assets/icons/frame.svg"
                                             @click="showEditForm(role)"
                                        />
                                    </div>
                                    <div class="control">
                                        <span class="material-icons-outlined" @click="remove(role)">delete</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components//ReusableComponents/BtnContainer.vue';
import UserRoleEdit from '@/index/components/Admin/UserRole/Edit.vue';

export default {
    name: 'UserRole',
    components: {
        BtnContainer,
        UserRoleEdit,
    },
    data() {
        return {
            title: 'Роли',
            isEditFormVisible: false,
            selectedRole: null,
        }
    },
    computed: {
        ...mapGetters([
            'roles',
            'operations',
            'oivs',
            'employees',
        ]),
    },
    watch: {
        isEditFormVisible(v) {
            this.title = v ? this.selectedRole ? 'Редактирование роли' : 'Создание роли' : 'Роли';
        },
        searchEmployee(v) {
            if (v && v.length > 2) {
                this.searchedEmployees = this.employees.filter(employee => {
                    if (employee.department && employee.department.toLowerCase().includes(v.toLowerCase())) {
                        return true;
                    }
                    if (employee.name.toLowerCase().includes(v.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
            } else {
                this.searchedEmployees = [];
            }
        }
    },
    methods: {
        ...mapActions([
            'removeRole',
        ]),
        showEditForm(role) {
            this.selectedRole = role;
            this.isEditFormVisible = true;
        },
        remove(role) {
            this.$store.commit('setRole', role);
            this.removeRole();
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.user-role-management
    .header
        position: relative
        line-height: 2.5em
        margin-bottom: 15px
        .btn-container
            position: absolute
            top: 0
            right: 0
            width: 150px
    .content
        > .control
            margin-bottom: 20px

h1, h2
    font-size: 18px

table
    border-collapse: collapse
    width: 100%
    max-width: 1000px
    th
        padding: 10px 20px
        font-size: 12px
        font-weight: normal
        text-align: left
    td
        padding: 10px 20px
        font-size: 12px
        border-bottom: 2px solid #fff
        background-color: $blue-light

.control-list
    display: flex
    justify-content: flex-end
    .control
        display: flex
        margin-right: 6px
        span
            font-size: 18px

</style>
