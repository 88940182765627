<template>
    <modal :title="modalTitle" @save="save" @close="$emit('close')">
        <div class="control">
            <div class="label-container">
                <span>Наименование</span>
            </div>
            <div class="input-container">
                <input type="text" v-model="title"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/index/components/ReusableComponents/Modal.vue';

export default {
    components: {
        Modal
    },
    data() {
        return {
            modalTitle: 'Редактировать ОИВ',
            title: '',
        }
    },
    methods: {
        save() {
            if (this.title.trim()) {
                this.$emit('update', this.title);
            }
        }
    },
    created() {

    },
    mounted() {
        this.title = this.$parent.selected.title;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.input-container
    input
        padding: 15px 20px

</style>
