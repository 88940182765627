<template>
    <modal :title="modalTitle" @save="update">
        <div class="control">
            <div class="label-container">
                <span>Группа</span>
            </div>
            <select-group @select="selectGroup" :value="group"/>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" v-model="targetTitle"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import SelectGroup from '../components/SelectGroup.vue';

export default {
    name: 'EditTargetModal',
    components: {
        Modal,
        SelectGroup,
    },
    data() {
        return {
            modalTitle: 'Редактировать цель',
            targetTitle: '',
            group: null,
        }
    },
    computed: {
        ...mapGetters([
            'data',
        ]),
    },
    methods: {
        ...mapActions([
            'updateTarget',
        ]),
        selectGroup(group) {
            this.group = group;
        },
        update() {
            if (this.targetTitle && this.group && this.targetTitle !== this.$parent.selectedIndicator.title) {
                this.$store.commit('setTarget', {
                    id: this.$parent.selectedIndicator.id,
                    title: this.targetTitle,
                    parent_id: this.group.id
                });
                this.updateTarget();
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.targetTitle = this.$parent.selectedIndicator.title;
        this.group = this.data.find(i => i.id === this.$parent.selectedIndicator.parent_id);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 40px
    &:last-child
        margin-bottom: 0
    span
        font-weight: 600
        font-size: $font-size
    .input-container
        margin-top: 16px
.label-container
    display: flex
    margin-bottom: 16px
    justify-content: space-between
    font-size: $font-size
    font-weight: 600

.btn-group
    .btn-container
        &:first-child
            margin-right: 15px
            width: 100px
        &:last-child
            width: 150px

.select-group-container
    display: flex
    .select-group
        flex-grow: 1
        &:first-child
            margin-right: 15px

</style>
