<template>
    <modal :title="modalTitle" @save="save" @close="$emit('close')">
        <div class="control">
            <div class="label-container">
                <span>Наименование</span>
            </div>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="title"/>
            </div>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Период</span>
            </div>
            <div class="text-container">
                <flat-pickr v-model="date" :config="config" @on-close="saveDates"></flat-pickr>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/index/components/ReusableComponents/Modal.vue';
import FlatPickr from 'vue-flatpickr-component';
import dateformat from 'dateformat';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/flatpickr.css';
import { i18n } from "dateformat";

i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
i18n.monthNames = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

export default {
    components: {
        Modal,
        FlatPickr,
    },
    data() {
        return {
            modalTitle: 'Редактировать интервалы применения подсветки',
            title: '',
            date: null,
            config: {
                mode: 'range',
                locale: Russian,
                dateFormat: 'j F',
                defaultDate: []
            },
            from: null,
            to: null,
        }
    },
    methods: {
        ...mapActions([
            'updateIndicationInterval',
        ]),
        saveDates(dates) {
            this.from = dateformat(dates[0], 'yyyy-mm-dd');
            this.to = dateformat(dates[1], 'yyyy-mm-dd');
        },
        save() {
            if (this.title && this.from && this.to) {
                const interval = {
                    id: this.$parent.selectedInterval.id,
                    title: this.title,
                    config: {
                        from: this.from,
                        to: this.to,
                    },
                    external_type: this.$parent.selectedInterval.external_type,
                    external_link_id: this.$parent.selectedInterval.external_link_id,
                };
                this.$store.commit('setIndicationInterval', interval);
                this.updateIndicationInterval();
                this.$emit('close');
            }
        }
    },
    created() {
        if (this.$parent.selectedInterval) {
            this.title = this.$parent.selectedInterval.title;
            this.from = new Date(this.$parent.selectedInterval.config.from);
            this.to = new Date(this.$parent.selectedInterval.config.to);
            this.config.defaultDate = [ this.from, this.to ];
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.control
    margin-bottom: 20px

</style>
