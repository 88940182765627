<template>
    <div>
        <upload-step v-if="steps[currentStepIndex].code === 'upload'" @commit="proceedStep"/>
        <result-step v-if="steps[currentStepIndex].code === 'result'" :data="result" @commit="currentStepIndex = 0"/>
    </div>
</template>

<script>
// @ is an alias to /src
import UploadStep from '@/index/components/Admin/Import/Steps/Upload.vue';
import ResultStep from '@/index/components/Admin/Import/Steps/Result.vue';

export default {
    components: {
        UploadStep,
        ResultStep,
    },
    data() {
        return {
            steps: [
                {
                    value: 1,
                    code: 'upload',
                },
                {
                    value: 2,
                    code: 'result',
                }
            ],
            result: null,
            currentStepIndex: 0,
        }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        proceedStep(result) {
            this.result = result;
            this.currentStepIndex++;
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>

<style lang="sass" scoped>
@import '../../../assets/index.sass'


</style>
