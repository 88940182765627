<template>
    <div class="additional-management">
        <div class="content">
            <h3>Квартальные значения года</h3>
            <year-select class="year-select"/>
            <h3>Комментарий квартала</h3>
            <table v-if="commentQuarter">
                <thead>
                <tr>
                    <th>Наименование</th>
                    <th>Период</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="interval in commentQuarter.intervals">
                    <tr>
                        <td>{{ interval.title }}</td>
                        <td>{{ calcPeriod(interval) }}</td>
                        <td>
                            <div class="control-list">
                                <div class="control">
                                    <img class="frame"
                                         src="~@/index/assets/icons/frame.svg"
                                         @click="showEditIntervalForm(interval)"
                                    />
                                </div>
                                <div class="control">
                                    <span class="material-icons-outlined" @click="">delete</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
            <h3>Отчетный квартал графиков</h3>
            <table v-if="commentQuarter">
                <thead>
                <tr>
                    <th>Наименование</th>
                    <th>Период</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="interval in chartQuarter.intervals">
                    <tr>
                        <td>{{ interval.title }}</td>
                        <td>{{ calcPeriod(interval) }}</td>
                        <td>
                            <div class="control-list">
                                <div class="control">
                                    <img class="frame"
                                         src="~@/index/assets/icons/frame.svg"
                                         @click="showEditIntervalForm(interval)"
                                    />
                                </div>
                                <div class="control">
                                    <span class="material-icons-outlined" @click="">delete</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
        <edit-interval-form v-if="isEditIntervalFormVisible" @close="isEditIntervalFormVisible = false"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import YearSelect from '@/index/components/Admin/Extra/YearSelect.vue';
import EditIntervalForm from '@/index/components/Admin/Extra/EditIntervalForm.vue';
import dateformat from 'dateformat';
import { i18n } from 'dateformat';
i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
i18n.monthNames = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

export default {
    components: {
        BtnContainer,
        YearSelect,
        EditIntervalForm,
    },
    data() {
        return {
            commentQuarter: null,
            chartQuarter: null,
            initialized: false,
            isEditIntervalFormVisible: false,
            selectedInterval: null,
        }
    },
    computed: {
        ...mapGetters([
            'settings',
        ]),
    },
    watch: {
        settings() {
            this.settings.map(setting => {
                switch (setting.code) {
                    case 'comment_quarter':
                        this.commentQuarter = setting;
                        break;
                    case 'chart_quarter':
                        this.chartQuarter = setting;
                        break;
                }
            });
        }
    },
    methods: {
        ...mapActions([

        ]),
        calcPeriod(interval) {
            const dateFrom = dateformat(interval.config.from, 'd mmm');
            const dateTo = dateformat(interval.config.to, 'd mmm');
            return `${dateFrom} - ${dateTo}`;
        },
        showEditIntervalForm(interval) {
            this.selectedInterval = interval;
            this.isEditIntervalFormVisible = true;
        }
    },
    created() {
        this.settings.map(setting => {
            switch (setting.code) {
                case 'comment_quarter':
                    this.commentQuarter = setting;
                    break;
                case 'chart_quarter':
                    this.chartQuarter = setting;
                    break;
            }
        });
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

ul
    margin: 0
    margin-bottom: 25px
    padding: 0
    padding-left: 20px
    list-style-type: none
    li
        padding: 2px 0

table
    border-collapse: collapse
    width: 100%
    max-width: 600px
    th
        padding: 10px 20px
        font-size: 12px
        font-weight: normal
        text-align: left
    td
        padding: 10px 20px
        font-size: 12px
        /*text-align: center*/
        border-bottom: 2px solid #fff
        background-color: $blue-light

.control-list
    display: flex
    justify-content: flex-end
    .control
        display: flex
        margin-right: 6px
        span
            font-size: 18px

.year-select
    max-width: 180px
</style>
