<template>
    <div class="strategic-panel">
        <div id="app-version">версия 1.8.3 последний релиз 14 марта 2023г</div>
        <div class="strategic-panel__header">
            <h1>Дашборд целей Комплекса</h1>
            <div class="admin-options">
            <!--<div class="admin-options">-->
                <!--<div class="control" v-if="user">-->
                    <!--<small-btn-container :icon="'open_in_new'" title="Открыть в новой вкладке" @click=""/>-->
                <!--</div>-->
                <div class="control flex" v-if="user && user.permissions.admin">
                    <div class="label-container">
                        <label>Админка</label>
                    </div>
                    <small-btn-container :icon="'settings'" @click="$router.push({ name: 'AdminDashboard' })"/>
                </div>
                <div class="control flex">
                    <div class="label-container">
                        <label>Аналитика по кварталу</label>
                    </div>
                    <small-btn-container :icon="'area_chart'" @click="goToPage('/chart')"/>
                </div>
            </div>
        </div>
        <div class="strategic-panel__content">
            <div class="controls">
                <div class="control">
                    <div class="input-container search">
                        <img src="~@/index/assets/icons/search.svg"/>
                        <input type="text" placeholder="Поиск" v-model="search" ref="search"/>
                    </div>
                </div>
                <div class="control">
                    <direction-filter/>
                </div>
                <!--<div class="control">-->
                    <!--<period-filter/>-->
                <!--</div>-->
                <div class="control" v-if="user">
                    <btn-container :primary="true" :title="'Сохранить фильтр'" :icon="'save_filter'" @click="saveUserFilter"/>
                </div>
                <div class="control">
                    <btn-container :title="'Экспорт'" :icon="'export'" @click="exportData"/>
                </div>
            </div>
            <div class="table-wrapper">
                <table id="strategic-table">
                    <thead>
                    <tr>
                        <th>
                            <span>Показатели</span>
                            <div class="control" @click="showTooltipIndicator">
                                <template v-if="isIndicatorFilterApplied">
                                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                        <path d="M 2.5567 2.7687 C 2.6429 2.6036 2.8137 2.5 3 2.5 H 13 C 13.1863 2.5 13.3571 2.6036 13.4433 2.7687 C 13.5295 2.9339 13.5167 3.1333 13.4101 3.2861 L 9.8333 8.4127 V 13 C 9.8333 13.1672 9.7498 13.3233 9.6107 13.416 C 9.4716 13.5088 9.2953 13.5258 9.141 13.4615 L 6.4744 12.3504 C 6.288 12.2728 6.1667 12.0907 6.1667 11.8889 V 8.4127 L 2.5899 3.2861 C 2.4833 3.1333 2.4705 2.9339 2.5567 2.7687 Z" fill="#B9181D"/>
                                    </svg>
                                </template>
                                <template v-else>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55669 2.76875C2.64286 2.60357 2.8137 2.5 3 2.5H13C13.1863 2.5 13.3571 2.60357 13.4433 2.76875C13.5295 2.93392 13.5167 3.1333 13.4101 3.28609L9.83333 8.41274V13C9.83333 13.1672 9.74978 13.3233 9.61068 13.416C9.47159 13.5088 9.29534 13.5258 9.14103 13.4615L6.47436 12.3504C6.28804 12.2728 6.16667 12.0907 6.16667 11.8889V8.41274L2.58994 3.28609C2.48334 3.1333 2.47053 2.93392 2.55669 2.76875ZM3.9585 3.5L7.07673 7.96946C7.13528 8.05338 7.16667 8.15324 7.16667 8.25556V11.5556L8.83333 12.25V8.25556C8.83333 8.15324 8.86473 8.05338 8.92327 7.96946L12.0415 3.5H3.9585Z" fill="#B9181D"/>
                                    </svg>
                                </template>
                            </div>
                            <!--<div class="cell-filler"></div>-->
                        </th>
                        <th class="bleached" rowspan="2">
                            <ul class="lines">
                                <li>Факт</li>
                                <li>План</li>
                            </ul>
                            <!--<div class="control arrow-in-circle" @click="collapseColumn">-->
                                <!--<span class="material-icons-outlined">chevron_left</span>-->
                            <!--</div>-->
                        </th>
                        <template v-for="period in filteredPeriods">
                            <template v-if="period.quarters && filteredQuarters.length">
                                <th class="quarter" v-for="quarter in filteredQuarters">
                                    <div class="cell-container">
                                        <div class="block">
                                            <div class="overlay"></div>
                                        </div>
                                        <div class="block">
                                            <span class="title">{{ quarter.title }}</span>
                                        </div>
                                    </div>
                                </th>
                                <th class="quarter">
                                    <div class="cell-container">
                                        <div class="block sum">
                                            <span>{{ period.title }}</span>
                                        </div>
                                        <div class="block">
                                            <span class="title">&#8721;</span>
                                        </div>
                                    </div>
                                </th>
                            </template>
                            <template v-else>
                                <th>
                                    <span>{{ period.title }}</span>
                                </th>
                            </template>
                        </template>
                        <th>
                            <span>ОИВ</span>
                            <div class="control" @click="showTooltipOiv">
                                <template v-if="isOivFilterApplied">
                                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                        <path d="M 2.5567 2.7687 C 2.6429 2.6036 2.8137 2.5 3 2.5 H 13 C 13.1863 2.5 13.3571 2.6036 13.4433 2.7687 C 13.5295 2.9339 13.5167 3.1333 13.4101 3.2861 L 9.8333 8.4127 V 13 C 9.8333 13.1672 9.7498 13.3233 9.6107 13.416 C 9.4716 13.5088 9.2953 13.5258 9.141 13.4615 L 6.4744 12.3504 C 6.288 12.2728 6.1667 12.0907 6.1667 11.8889 V 8.4127 L 2.5899 3.2861 C 2.4833 3.1333 2.4705 2.9339 2.5567 2.7687 Z" fill="#B9181D"/>
                                    </svg>
                                </template>
                                <template v-else>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55669 2.76875C2.64286 2.60357 2.8137 2.5 3 2.5H13C13.1863 2.5 13.3571 2.60357 13.4433 2.76875C13.5295 2.93392 13.5167 3.1333 13.4101 3.28609L9.83333 8.41274V13C9.83333 13.1672 9.74978 13.3233 9.61068 13.416C9.47159 13.5088 9.29534 13.5258 9.14103 13.4615L6.47436 12.3504C6.28804 12.2728 6.16667 12.0907 6.16667 11.8889V8.41274L2.58994 3.28609C2.48334 3.1333 2.47053 2.93392 2.55669 2.76875ZM3.9585 3.5L7.07673 7.96946C7.13528 8.05338 7.16667 8.15324 7.16667 8.25556V11.5556L8.83333 12.25V8.25556C8.83333 8.15324 8.86473 8.05338 8.92327 7.96946L12.0415 3.5H3.9585Z" fill="#B9181D"/>
                                    </svg>
                                </template>
                            </div>
                        </th>
                        <th>
                            <span>Комментарий за предыдущий квартал</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="indicator in filteredData">
                        <template v-if="indicator.is_hidden === false">
                            <template v-if="mergedRows.includes(indicator.type)">

                                <template v-if="indicator.type === 'direction'">
                                    <tr class="merged category-1">
                                        <td colspan="11">
                                            <label>Направление №{{ indicator.order_title }}</label>
                                            <span v-html="indicator.title"></span>
                                            <div class="control" v-if="user && user.permissions.indicator_tree">
                                                <img class="more" src="~@/index/assets/icons/option.svg" @click="showTooltipDirectionMenu(indicator, $event)"/>
                                            </div>
                                            <span class="group-arc arc-1"></span>
                                        </td>
                                    </tr>
                                </template>

                                <template v-if="indicator.type === 'group'">
                                    <tr class="merged category-2">
                                        <td colspan="11" class="group-2">
                                            <label>Группа</label>
                                            <span v-html="indicator.title"></span>
                                            <div class="control" v-if="user && user.permissions.indicator_tree">
                                                <img class="more" src="~@/index/assets/icons/option.svg" @click="showTooltipGroupMenu(indicator, $event)"/>
                                            </div>
                                            <div class="group-line-container container-1">
                                                <span class=""></span>
                                            </div>
                                            <span class="group-arc arc-2"></span>
                                        </td>
                                    </tr>
                                </template>

                                <template v-if="indicator.type === 'target'">
                                    <tr class="merged category-3">
                                        <td colspan="11" class="group-3">
                                            <label>Цель {{ indicator.order_title }}</label>
                                            <span v-html="indicator.title"></span>
                                            <div class="control" v-if="user && user.permissions.indicator_tree">
                                                <img class="more" src="~@/index/assets/icons/option.svg" @click="showTooltipTargetMenu(indicator, $event)"/>
                                            </div>
                                            <template v-if="indicator.parent.is_hidden">
                                                <div class="group-line-container container-1">
                                                    <span class=""></span>
                                                </div>
                                                <span class="group-arc arc-2"></span>
                                            </template>
                                            <template v-else>
                                                <div class="group-line-container container-1">
                                                    <span class=""></span>
                                                </div>
                                                <div class="group-line-container container-2">
                                                    <span class=""></span>
                                                </div>
                                                <span class="group-arc arc-3"></span>
                                            </template>
                                        </td>
                                    </tr>
                                </template>

                            </template>
                            <template v-else>
                                <tr class="row-indicator">
                                    <td class="group-cell-1">
                                        <div class="controls">
                                            <div class="title">
                                                <span>Показатель {{ indicator.order_title }}</span>
                                            </div>
                                            <div class="values">
                                                <span class="unit-measure">{{ indicator.unit_measure.title }}</span>
                                                <span v-if="user && user.permissions.indicator_tree" class="value_type" :title="indicator.value_type">{{ indicator.value_type[0] }}</span>
                                                <span class="calculation_type" :title="indicator.comment">?</span>
                                                <img v-if="user && user.permissions.indicator_tree" class="more" src="~@/index/assets/icons/option.svg" @click="showTooltipIndicatorMenu(indicator, $event)"/>
                                            </div>
                                        </div>
                                        <span v-html="indicator.title"></span>
                                        <template v-if="indicator.parent.parent.is_hidden">
                                            <div class="group-line-container container-1">
                                                <span class=""></span>
                                            </div>
                                            <div class="group-line-container container-2">
                                                <span class=""></span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="group-line-container container-1">
                                                <span class=""></span>
                                            </div>
                                            <div class="group-line-container container-2">
                                                <span class=""></span>
                                            </div>
                                            <div class="group-line-container container-3">
                                                <span class=""></span>
                                            </div>
                                        </template>
                                    </td>
                                    <td class="group-cell-2 bleached">
                                        <div class="collapsible-container">
                                            <div>
                                                <ul class="lines">
                                                    <li>Факт</li>
                                                    <li>План</li>
                                                    <li>%</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                    <template v-for="period in filteredPeriods">
                                        <template v-if="period.quarters && period.quarters.length">
                                            <template v-for="quarter in filteredQuarters">
                                                <template v-if="quarter.value === 1">
                                                    <td class="group-cell-2 values" :class="{ highlighted: checkHighlight(quarter) }">
                                                        <div class="controls">
                                                            <img v-if="indicator.periods && indicator.periods.some(p => p.period_id === quarter.id) && indicator.periods.find(p => p.period_id === quarter.id).robotic" class="robot" src="~@/index/assets/icons/robot.svg"/>
                                                            <img v-if="user && user.permissions.indicator_values_quarter_1_modal && user.oiv_ids.some(oiv_id => indicator.oiv_ids.includes(oiv_id))"
                                                                 class="frame"
                                                                 src="~@/index/assets/icons/frame.svg"
                                                                 @click="showEditModalCurrentPeriodPreviousQuarter(indicator, period, quarter)"
                                                            />
                                                            <span v-if="calcIndication(indicator, quarter)" class="indication" :style="{ 'background-color': calcIndication(indicator, quarter) }"></span>
                                                        </div>
                                                        <ul v-if="indicator.periods && indicator.periods.some(p => p.period_id === quarter.id)" class="lines data-values">
                                                            <li>{{ formatV(indicator.periods.find(p => p.period_id === quarter.id).fact) }}&nbsp;</li>
                                                            <li>{{ formatV(indicator.periods.find(p => p.period_id === quarter.id).plan) }}&nbsp;</li>
                                                            <li>{{ formatP(indicator.periods.find(p => p.period_id === quarter.id).calculated) }}&nbsp;</li>
                                                        </ul>
                                                    </td>
                                                </template>
                                                <template v-if="quarter.value > 1">
                                                    <td class="group-cell-2 values" :class="{ highlighted: checkHighlight(quarter) }">
                                                        <div class="controls">
                                                            <img v-if="indicator.periods && indicator.periods.some(p => p.period_id === quarter.id) && indicator.periods.find(p => p.period_id === quarter.id).robotic" class="robot" src="~@/index/assets/icons/robot.svg"/>
                                                            <img v-if="user && user.permissions[`indicator_values_quarter_${quarter.value}_modal`] && user.oiv_ids.some(oiv_id => indicator.oiv_ids.includes(oiv_id))"
                                                                 class="frame"
                                                                 src="~@/index/assets/icons/frame.svg"
                                                                 @click="showEditModalCurrentPeriodCurrentQuarter(indicator, period, quarter)"
                                                            />
                                                            <span v-if="calcIndication(indicator, quarter)" class="indication" :style="{ 'background-color': calcIndication(indicator, quarter) }"></span>
                                                        </div>
                                                        <ul v-if="indicator.periods && indicator.periods.some(p => p.period_id === quarter.id)" class="lines data-values">
                                                            <li>{{ formatV(indicator.periods.find(p => p.period_id === quarter.id).fact) }}&nbsp;</li>
                                                            <li>{{ formatV(indicator.periods.find(p => p.period_id === quarter.id).plan) }}&nbsp;</li>
                                                            <li>{{ formatP(indicator.periods.find(p => p.period_id === quarter.id).calculated) }}&nbsp;</li>
                                                        </ul>
                                                    </td>
                                                </template>
                                            </template>
                                            <td class="group-cell-2 values">
                                                <div class="controls">
                                                    <img v-if="user && user.permissions.indicator_values"
                                                         class="frame"
                                                         src="~@/index/assets/icons/frame.svg"
                                                         @click="showEditModalPreviousPeriod(indicator, period)"
                                                    />
                                                    <span v-if="calcIndication(indicator, period)" class="indication" :style="{ 'background-color': calcIndication(indicator, period) }"></span>
                                                </div>
                                                <ul v-if="indicator.periods && indicator.periods.some(p => p.period_id === period.id)" class="lines data-values">
                                                    <li>{{ formatV(indicator.periods.find(p => p.period_id === period.id).fact) }}&nbsp;</li>
                                                    <li>{{ formatV(indicator.periods.find(p => p.period_id === period.id).plan) }}&nbsp;</li>
                                                    <li>{{ formatP(indicator.periods.find(p => p.period_id === period.id).calculated) }}&nbsp;</li>
                                                </ul>
                                            </td>
                                        </template>
                                        <template v-else>
                                            <template v-if="period.value > currentYear">
                                                <td class="group-cell-2 values">
                                                    <div class="controls"
                                                         v-if="user && user.permissions.indicator_values">
                                                        <img class="frame"
                                                             src="~@/index/assets/icons/frame.svg"
                                                             @click="showEditModalNextPeriod(indicator, period)"
                                                        />
                                                    </div>
                                                    <ul v-if="indicator.periods && indicator.periods.some(p => p.period_id === period.id)" class="lines data-values">
                                                        <li>{{ formatV(indicator.periods.find(p => p.period_id === period.id).fact) }}&nbsp;</li>
                                                        <li>{{ formatV(indicator.periods.find(p => p.period_id === period.id).plan) }}&nbsp;</li>
                                                        <li>{{ formatP(indicator.periods.find(p => p.period_id === period.id).calculated) }}&nbsp;</li>
                                                    </ul>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td class="group-cell-2 values">
                                                    <div class="controls"
                                                         v-if="user && user.permissions.indicator_values">
                                                        <img class="frame"
                                                             src="~@/index/assets/icons/frame.svg"
                                                             @click="showEditModalPreviousPeriod(indicator, period)"
                                                        />
                                                    </div>
                                                    <ul v-if="indicator.periods && indicator.periods.some(p => p.period_id === period.id)" class="lines data-values">
                                                        <li>{{ formatV(indicator.periods.find(p => p.period_id === period.id).fact) }}&nbsp;</li>
                                                        <li>{{ formatV(indicator.periods.find(p => p.period_id === period.id).plan) }}&nbsp;</li>
                                                        <li>{{ formatP(indicator.periods.find(p => p.period_id === period.id).calculated) }}&nbsp;</li>
                                                    </ul>
                                                </td>
                                            </template>
                                        </template>
                                    </template>
                                    <td>
                                        <span v-html="indicator.oivs.map(o => o.title).join(', ')"></span>
                                    </td>
                                    <td>
                                        <!--<span v-html="indicator.comment"></span>-->
                                        <div>
                                            <span>{{ getComment(indicator) }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
        <edit-modal-previous-period
            v-if="isEditModalPreviousPeriodVisible"
            @close="isEditModalPreviousPeriodVisible = false"
        />
        <edit-modal-current-period-previous-quarter
            v-if="isEditModalCurrentPeriodPreviousQuarterVisible"
            @close="isEditModalCurrentPeriodPreviousQuarterVisible = false"
        />
        <edit-modal-current-period-current-quarter
            v-if="isEditModalCurrentPeriodCurrentQuarterVisible"
            @close="isEditModalCurrentPeriodCurrentQuarterVisible = false"
        />
        <edit-modal-next-period
            v-if="isEditModalNextPeriodVisible"
            @close="isEditModalNextPeriodVisible = false"
        />
        <tooltip-indicator-filter
            v-if="isTooltipIndicatorFilterVisible"
            :coordinates="tooltipCoordinates"
            @close="isTooltipIndicatorFilterVisible = false"
        />
        <tooltip-oiv-filter
            v-if="isTooltipOivFilterVisible"
            :coordinates="tooltipCoordinates"
            @close="isTooltipOivFilterVisible = false"
        />
        <tooltip-direction-menu
            v-if="isTooltipDirectionMenuVisible"
            :coordinates="tooltipCoordinates"
            :direction="selectedIndicator"
            :max="directions.length"
            @createDirection="isCreateDirectionModalVisible = true"
            @createGroup="isCreateGroupModalVisible = true"
            @createTarget="showTargetModal(true)"
            @editDirection="isEditDirectionModalVisible = true"
            @deleteDirection="isDeleteDirectionModalVisible = true"
            @close="isTooltipDirectionMenuVisible = false"
        />
        <tooltip-group-menu
            v-if="isTooltipGroupMenuVisible"
            :coordinates="tooltipCoordinates"
            :group="selectedIndicator"
            :max="data.filter(i => i.type === 'group' && i.parent_id === selectedIndicator.parent_id).length"
            @createGroup="isCreateGroupModalVisible = true"
            @createTarget="showTargetModal"
            @editGroup="isEditGroupModalVisible = true"
            @deleteGroup="isDeleteGroupModalVisible = true"
            @close="isTooltipGroupMenuVisible = false"
        />
        <tooltip-target-menu
            v-if="isTooltipTargetMenuVisible"
            :coordinates="tooltipCoordinates"
            :target="selectedIndicator"
            :max="data.filter(i => i.type === 'target' && i.parent_id === selectedIndicator.parent_id).length"
            @createTarget="showTargetModal"
            @createIndicator="isCreateIndicatorModalVisible = true"
            @editTarget="isEditTargetModalVisible = true"
            @deleteTarget="isDeleteTargetModalVisible = true"
            @close="isTooltipTargetMenuVisible = false"
        />
        <tooltip-indicator-menu
            v-if="isTooltipIndicatorMenuVisible"
            :coordinates="tooltipCoordinates"
            :indicator="selectedIndicator"
            :max="data.filter(i => i.type === 'indicator' && i.parent_id === selectedIndicator.parent_id).length"
            @createIndicator="isCreateIndicatorModalVisible = true"
            @editIndicator="isEditIndicatorModalVisible = true"
            @deleteIndicator="isDeleteIndicatorModalVisible = true"
            @close="isTooltipIndicatorMenuVisible = false"
        />
        <create-indicator-modal
            v-if="isCreateIndicatorModalVisible"
            @close="isCreateIndicatorModalVisible = false"
        />
        <create-target-modal
            v-if="isCreateTargetModalVisible"
            @close="isCreateTargetModalVisible = false"
        />
        <create-group-modal
            v-if="isCreateGroupModalVisible"
            @close="isCreateGroupModalVisible = false"
        />
        <create-direction-modal
            v-if="isCreateDirectionModalVisible"
            @close="isCreateDirectionModalVisible = false"
        />
        <edit-direction-modal
            v-if="isEditDirectionModalVisible"
            @close="isEditDirectionModalVisible = false"
        />
        <edit-group-modal
            v-if="isEditGroupModalVisible"
            @close="isEditGroupModalVisible = false"
        />
        <edit-target-modal
            v-if="isEditTargetModalVisible"
            @close="isEditTargetModalVisible = false"
        />
        <edit-indicator-modal
            v-if="isEditIndicatorModalVisible"
            @close="isEditIndicatorModalVisible = false"
        />
        <delete-direction-modal
            v-if="isDeleteDirectionModalVisible"
            @close="isDeleteDirectionModalVisible = false"
        />
        <delete-group-modal
            v-if="isDeleteGroupModalVisible"
            @close="isDeleteGroupModalVisible = false"
        />
        <delete-target-modal
            v-if="isDeleteTargetModalVisible"
            @close="isDeleteTargetModalVisible = false"
        />
        <delete-indicator-modal
            v-if="isDeleteIndicatorModalVisible"
            @close="isDeleteIndicatorModalVisible = false"
        />
        <save-filter-result-modal
            v-if="isSaveFilterResultModalVisible"
            @close="isSaveFilterResultModalVisible = false"
        />
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import DirectionFilter from '../components/DirectionFilter.vue';
import PeriodFilter from '../components/PeriodFilter.vue';
import EditModalPreviousPeriod from '../components/EditModalPreviousPeriod.vue';
import EditModalCurrentPeriodPreviousQuarter from '../components/EditModalCurrentPeriodPreviousQuarter.vue';
import EditModalCurrentPeriodCurrentQuarter from '../components/EditModalCurrentPeriodCurrentQuarter.vue';
import EditModalNextPeriod from '../components/EditModalNextPeriod.vue';
import TooltipIndicatorFilter from '../components/TooltipIndicatorFilter.vue';
import TooltipOivFilter from '../components/TooltipOivFilter.vue';
import TooltipDirectionMenu from '../components/TooltipDirectionMenu.vue';
import TooltipGroupMenu from '../components/TooltipGroupMenu.vue';
import TooltipIndicatorMenu from '../components/TooltipIndicatorMenu.vue';
import TooltipTargetMenu from '../components/TooltipTargetMenu.vue';
import CreateIndicatorModal from '../components/CreateIndicatorModal.vue';
import CreateTargetModal from '../components/CreateTargetModal.vue';
import CreateGroupModal from '../components/CreateGroupModal.vue';
import CreateDirectionModal from '../components/CreateDirectionModal.vue';
import EditDirectionModal from '../components/EditDirectionModal.vue';
import EditGroupModal from '../components/EditGroupModal.vue';
import EditTargetModal from '../components/EditTargetModal.vue';
import EditIndicatorModal from '../components/EditIndicatorModal.vue';
import DeleteDirectionModal from '../components/DeleteDirectionModal.vue';
import DeleteGroupModal from '../components/DeleteGroupModal.vue';
import DeleteTargetModal from '../components/DeleteTargetModal.vue';
import DeleteIndicatorModal from '../components/DeleteIndicatorModal.vue';
import BtnContainer from '../components/ReusableComponents/BtnContainer.vue';
import SmallBtnContainer from '../components/ReusableComponents/SmallBtnContainer.vue';
import FormatDataHelper from '../Helpers/Formatter';
import AdvancedScrolling from '../Helpers/AdvancedScrolling';
import Calculation from "../Helpers/Calculation";
import SaveFilterResultModal from "../components/SaveFilterResultModal.vue";
import Api from '../store/api';

export default {
    name: 'StrategicDashboard',
    components: {
        DirectionFilter,
        PeriodFilter,
        EditModalPreviousPeriod,
        EditModalCurrentPeriodPreviousQuarter,
        EditModalCurrentPeriodCurrentQuarter,
        EditModalNextPeriod,
        TooltipIndicatorFilter,
        TooltipOivFilter,
        TooltipDirectionMenu,
        TooltipGroupMenu,
        TooltipIndicatorMenu,
        TooltipTargetMenu,
        CreateIndicatorModal,
        CreateTargetModal,
        CreateGroupModal,
        CreateDirectionModal,
        EditDirectionModal,
        EditGroupModal,
        EditTargetModal,
        EditIndicatorModal,
        DeleteDirectionModal,
        DeleteGroupModal,
        DeleteTargetModal,
        DeleteIndicatorModal,
        BtnContainer,
        SmallBtnContainer,
        SaveFilterResultModal,
    },
    data() {
        return {
            isEditModalPreviousPeriodVisible: false,
            isEditModalCurrentPeriodPreviousQuarterVisible: false,
            isEditModalCurrentPeriodCurrentQuarterVisible: false,
            isEditModalNextPeriodVisible: false,
            isTooltipIndicatorFilterVisible: false,
            isTooltipOivFilterVisible: false,
            isTooltipDirectionMenuVisible: false,
            isTooltipGroupMenuVisible: false,
            isTooltipIndicatorMenuVisible: false,
            isTooltipTargetMenuVisible: false,
            isCreateIndicatorModalVisible: false,
            isCreateTargetModalVisible: false,
            isCreateGroupModalVisible: false,
            isCreateDirectionModalVisible: false,
            isEditDirectionModalVisible: false,
            isEditGroupModalVisible: false,
            isEditTargetModalVisible: false,
            isEditIndicatorModalVisible: false,
            isDeleteDirectionModalVisible: false,
            isDeleteGroupModalVisible: false,
            isDeleteTargetModalVisible: false,
            isDeleteIndicatorModalVisible: false,
            tooltipCoordinates: null,
            mergedRows: ['direction', 'group', 'target'],
            lastGroupOrder: null,
            lastTargetOrder: null,
            selectedIndicator: null,
            selectedPeriod: null,
            selectedQuarter: null,
            selectedPeriodIndex: null,
            search: '',
            currentYear: null,
            noGroup: true,
            isAdminSettingsModalVisible: false,
            isFilterReadyToSave: false,
            isSaveFilterResultModalVisible: false,
        }
    },
    computed: {
        ...mapGetters([
            'initialized',
            'user',
            'data',
            'periods',
            'filteredData',
            'filteredDirections',
            'directions',
            'filteredOivs',
            'oivs',
            'filteredIndicators',
            'indicators',
            'filteredPeriods',
            'filteredQuarters',
            'unitMeasures',
            'isIndicatorFilterApplied',
            'isOivFilterApplied',
            'reportQuarter',
            'indications',
            'indicationIntervals',
            'settings',
            'userFilterConfig',
        ]),
    },
    watch: {
        filteredDirections() {
            this.applyFilters();
        },
        filteredOivs() {
            this.applyFilters();
        },
        filteredIndicators() {
            this.applyFilters();
        },
        search() {
            this.applyFilters();
        },
    },
    methods: {
        ...mapActions([
            'init',
            'exportData',
            'saveFilter',
        ]),
        showTooltipIndicator(event) {
            this.tooltipCoordinates = {
                top: event.clientY,
                left: event.clientX,
            };
            this.isTooltipIndicatorFilterVisible = true;
        },
        showTooltipOiv(event) {
            this.tooltipCoordinates = {
                top: event.clientY,
                left: event.clientX,
            };
            this.isTooltipOivFilterVisible = true;
        },
        showTooltipDirectionMenu(direction, event) {
            this.tooltipCoordinates = {
                top: event.clientY,
                left: event.clientX,
            };
            this.selectedIndicator = direction;
            this.isTooltipDirectionMenuVisible = true;
        },
        showTooltipGroupMenu(group, event) {
            this.tooltipCoordinates = {
                top: event.clientY,
                left: event.clientX,
            };
            this.selectedIndicator = group;
            this.isTooltipGroupMenuVisible = true;
        },
        showTooltipIndicatorMenu(indicator, event) {
            this.tooltipCoordinates = {
                top: event.clientY,
                left: event.clientX,
            };
            this.selectedIndicator = indicator;
            this.isTooltipIndicatorMenuVisible = true;
        },
        showTooltipTargetMenu(target, event) {
            this.tooltipCoordinates = {
                top: event.clientY,
                left: event.clientX,
            };
            this.selectedIndicator = target;
            this.isTooltipTargetMenuVisible = true;
        },
        getQuarter(date) {
            return Math.floor((date.getMonth() + 3) / 3);
        },
        showEditModalPreviousPeriod(indicator, period) {
            this.selectedIndicator = indicator;
            this.selectedPeriod = period;
            this.isEditModalPreviousPeriodVisible = true;
        },
        showEditModalNextPeriod(indicator, period) {
            this.selectedIndicator = indicator;
            this.selectedPeriod = period;
            this.isEditModalNextPeriodVisible = true;
        },
        showEditModalCurrentPeriodPreviousQuarter(indicator, period, quarter) {
            this.selectedIndicator = indicator;
            this.selectedPeriod = period;
            this.selectedQuarter = quarter;
            this.isEditModalCurrentPeriodPreviousQuarterVisible = true;
        },
        showEditModalCurrentPeriodCurrentQuarter(indicator, period, quarter) {
            this.selectedIndicator = indicator;
            this.selectedPeriod = period;
            this.selectedQuarter = quarter;
            this.isEditModalCurrentPeriodCurrentQuarterVisible = true;
        },
        showTargetModal(noGroup = false) {
            this.noGroup = noGroup;
            this.isCreateTargetModalVisible = true;
        },
        formatV(value) {
            return value
                ? isNaN(value) ? value : FormatDataHelper.formatDecimal(value)
                : value;
        },
        formatP(value) {
            return value
                ? isNaN(value) ? value : FormatDataHelper.localize(FormatDataHelper.formatPercent(value))
                : value;
        },
        filterByString(s) {
            const clonedSource = JSON.parse(JSON.stringify(this.filteredData));
            let pathIds = [];
            const filteredData = clonedSource
                .filter(i => {
                    if (i.type !== 'indicator') {
                        return true;
                    }
                    let isMatched = false;
                    // 2 - add highlight
                    const highlightRegExp = new RegExp(s, 'gi');
                    if (i.title.toLowerCase().search(s.toLowerCase()) !== -1) {
                        i.title = i.title.replace(highlightRegExp, (match) => `<mark>${match}</mark>`);
                        isMatched = true;
                    }
                    if (i.oiv && i.oiv.toLowerCase().search(s.toLowerCase()) !== -1) {
                        i.oiv = i.oiv.replace(highlightRegExp, (match) => `<mark>${match}</mark>`);
                        isMatched = true;
                    }
                    if (i.comment && i.comment.toLowerCase().search(s.toLowerCase()) !== -1) {
                        i.comment = i.comment.replace(highlightRegExp, (match) => `<mark>${match}</mark>`);
                        isMatched = true;
                    }
                    if (isMatched) {
                        pathIds = pathIds.concat(i.path_id.split('/').slice(0, -1));
                    }
                    return isMatched;
                }).filter(i => i.type === 'indicator' || (i.type !== 'indicator' && pathIds.includes(i.id.toString())));
            this.$store.commit('setFilteredData', filteredData);
        },
        applyFilters() {
            let pathIds = [];
            if (this.filteredDirections.length < this.directions.length) {
                pathIds = this.filteredDirections.map(d => d.id.toString());
                this.data.filter(i => {
                    if (pathIds.includes(i.parent_id.toString())) {
                        pathIds.push(i.id.toString());
                    }
                });
            }
            // apply direction filter
            let data = this.data.filter(i => this.filteredDirections.filter(d => i.path_id.split('/').includes(d.id.toString())).length > 0);
            // apply oiv filter
            data = data.filter(i => i.type !== 'indicator' || this.filteredOivs.filter(o => i.oiv_ids.includes(o.id)).length > 0);
            // apply indicator filter
            data = data.filter(i => i.type !== 'indicator' || this.filteredIndicators.filter(fi => fi.id === i.id).length > 0);
            if (
                this.filteredDirections.length < this.directions.length
                || this.filteredOivs.length < this.oivs.length
                || this.filteredIndicators.length < this.indicators.length
                || this.search
            ) {
                // remove empty parents
                data = data.filter(i => {
                    if (i.type === 'indicator') {
                        pathIds = pathIds.concat(i.path_id.split('/').slice(0, -1))
                    }
                    return i;
                }).filter(i => i.type === 'indicator' || (i.type !== 'indicator' && pathIds.includes(i.id.toString())));

            }
            this.$store.commit('setFilteredData', data);
            this.search && this.filterByString(this.search);
            // if (this.filteredData.length || this.filteredOivs.length || this.filteredIndicators.length) {
            //     this.isFilterReadyToSave = true;
            // }
        },
        collapseColumn() {
            document.querySelectorAll('table div.collapsible-container').forEach(el => {
                el.classList.toggle('collapsed');
            });
        },
        calcIndication(indicator, period) {
            const searchPeriodId = period.type === 'year' ? 1 : period.id;
            const intervalPeriod = this.indicationIntervals.find(i => i.external_link_id === searchPeriodId && ['year', 'quarter'].includes(i.external_type));
            if (!intervalPeriod) {
                return null;
            }
            if (Calculation.checkInterval(intervalPeriod.config.from, intervalPeriod.config.to)) {
                let calculated = null;
                if (indicator.periods && indicator.periods.length) {
                    const values = indicator.periods.find(p => p.period_id === period.id);
                    calculated = values ? values.calculated : null;
                }
                let result = null;
                if (calculated) {
                    if (period.type === 'quarter') {
                        const sortedIndications = this.indications.filter(i => i.type === 'quarter').sort((a, b) => { return a.order > b.order ? 1 : -1 });
                        for (let i = 0, counter_i = sortedIndications.length; i < counter_i; i++) {
                            const indication = sortedIndications[i];
                            switch (indication.compare) {
                                case 'lt':
                                    if (calculated < indication.value) {
                                        result = indication.color;
                                    }
                                    break;
                                case 'ge':
                                    if (calculated >= indication.value) {
                                        result = indication.color;
                                    }
                                    break;
                            }
                        }
                    } else {
                        const sortedIndications = this.indications.filter(i => i.type === 'year').sort((a, b) => { return a.order > b.order ? 1 : -1 });
                        for (let i = 0, counter_i = sortedIndications.length; i < counter_i; i++) {
                            const indication = sortedIndications[i];
                            switch (indication.compare) {
                                case 'lt':
                                    if (calculated < indication.value) {
                                        result = indication.color;
                                    }
                                    break;
                                case 'ge':
                                    if (calculated >= indication.value) {
                                        result = indication.color;
                                    }
                                    break;
                            }
                        }
                    }
                }
                return result;
            }

            return null;
        },
        checkHighlight(quarter) {
            const interval = this.indicationIntervals.find(i => i.external_link_id === quarter.id && i.external_type === 'report');
            if (!interval) {
                return false;
            }
            return Calculation.checkInterval(interval.config.from, interval.config.to);
        },
        getComment(indicator) {
            if (indicator.periods.length && this.settings.length) {
                const commentQuarterSetting = this.settings.find(setting => setting.code === 'comment_quarter');
                const reportQuarterInterval = commentQuarterSetting.intervals.map(interval => {
                    if (Calculation.checkInterval(interval.config.from, interval.config.to)) {
                        return interval;
                    } else {
                        return null;
                    }
                }).filter(interval => interval !== null).pop();
                if (reportQuarterInterval) {
                    const indicatorValue = indicator.periods.find(p => p.type === 'quarter' && p.period.id === reportQuarterInterval.external_link_id);
                    return indicatorValue ? indicatorValue.comment : null;
                }
            }
        },
        saveUserFilter() {
            const config = {};
            if (this.filteredDirections.length) {
                config.directions = this.filteredDirections.map(f => f.id);
            }
            if (this.filteredOivs.length) {
                config.oivs = this.filteredOivs.map(f => f.id);
            }
            if (this.filteredIndicators.length) {
                config.indicators = this.filteredIndicators.map(f => f.id);
            }
            this.$store.commit('setUserFilterConfig', Object.assign({}, this.userFilterConfig, config));
            this.saveFilter()
                .then(result => {
                    this.isSaveFilterResultModalVisible = true;
                });
        },
        goToPage(path) {
            window.location.href = path;
        }
    },
    created() {
        const now = new Date();
        const quarter = this.getQuarter(now);
        this.$store.commit('setReportQuarter', quarter === 4 ? 4 : quarter - 1);
        // this.$store.commit('setReportQuarter', quarter);
        this.currentYear = now.getFullYear();
        this.init();
    },
    mounted() {
        this.$refs.search.focus();
        AdvancedScrolling();
        Api.checkServerStatusUpdate();
    }
}
</script>

<style lang="sass">
@import '../assets/index.sass'

#app-version
    position: absolute
    top: 0
    right: 0
    margin-right: 35px
    font-size: 10px
    color: $gray-600-color

.strategic-panel
    .strategic-panel__header
        position: relative
        margin-bottom: 30px
        h1
            font-size: 24px
            text-align: left
            line-height: 32px
        .admin-options
            position: absolute
            top: 0
            right: 0
            display: flex
            .control:not(:last-child)
                margin-right: 6px
    .strategic-panel__content
        > .controls
            display: flex
            margin-bottom: 35px
            > .control
                &:not(:last-child)
                    margin-right: 15px
                &:nth-child(1)
                    flex-grow: 1
                &:nth-child(2)
                    flex-grow: 1
                &:nth-child(3)
                    width: 200px
                &:nth-child(4)
                    width: 200px

/* Base table styling */
$colWidth: 120px
.table-wrapper
    width: 100%
    table
        position: relative
        margin-bottom: 50px
        border-collapse: collapse
        width: 100%
        font-size: 12px
        tr
            &.merged
                width: 100%
                .control
                    position: absolute
                    top: 0
                    right: 0
                    height: 100%
                    display: flex
                    flex-direction: column
                    justify-content: center
                    padding-right: 10px
                &.collapsed
                    td
                        background-color: #fff
                td
                    padding-right: 25px
                    background-color: $blue-light
                    label
                        margin-right: 15px
                        padding: 1px 6px
                        color: #fff
                        font-weight: 500
                        border-radius: $border-radius
                        background-color: $brand-secondary-color
        th, td
            box-sizing: border-box
            position: relative
            padding: 12px
            font-weight: 400
            border: 1px solid $gray-300-color
            &:first-child
                border-left: none
            &:last-child
                border-right: none
            &.bleached
                background-color: $gray-50-color
            &.highlighted
                background-color: $brand-secondary-light-color
            span
                color: $gray-900-color
            &:nth-child(1)
                width: 350px
                text-align: left
            &:nth-child(2)
                width: 50px
                text-align: left
            &:nth-child(3)
                width: $colWidth
                text-align: right
            &:nth-child(4)
                width: $colWidth
                text-align: right
            &:nth-child(5)
                width: $colWidth
                text-align: right
            &:nth-child(6)
                width: $colWidth
                text-align: right
            &:nth-child(7)
                width: $colWidth
                text-align: right
            &:nth-child(8)
                width: $colWidth
                text-align: right
            &:nth-child(9)
                width: $colWidth
                text-align: right
            &:nth-child(10)
                width: $colWidth
            &:nth-child(11)
                width: auto
        th:nth-child(10)
            padding-right: 35px
            text-align: right
        td:nth-child(10)
            vertical-align: top
            text-align: left
        th
            position: sticky
            top: 0
            padding: 10px
            background-color: #fff
            z-index: 2
            .control
                img
                    filter: invert(14%) sepia(82%) saturate(4841%) hue-rotate(350deg) brightness(77%) contrast(92%)
            &:after
                content: ''
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                border-right: 1px solid $gray-300-color
                border-bottom: 1px solid $gray-300-color
                background-color: #fff
                z-index: -1
            &:nth-child(2):after
                background-color: $gray-50-color
            &:last-child:after
                border-right: none
            &.bleached
                .lines
                    margin-top: 6px
        td
            span
                font-weight: 500
                line-height: 1.25
        td.values
            text-align: right
            span
                font-weight: 400
        td.bleached
            li
                color: $gray-500-color
        tbody
            tr
                &.merged
                    td:first-child::before
                        content: ''
                        position: absolute
                        top: 0
                        left: 0
                        width: 100%
                        height: 100%
                        /*border-right: 1px solid #E0E0E0*/
                        border-bottom: 1px solid #E0E0E0
                        background-color: #fff
                        z-index: -1
                    span.group-arc
                        &::before
                            content: ''
                            position: absolute
                            display: inline-block
                            top: 50%
                            height: 50%
                            border-top: 1px solid $brand-secondary-color
                            border-left: 1px solid $brand-secondary-color
                            border-top-left-radius: 6px
                        &.arc-1::before
                            left: -20px
                            width: 35px
                        &.arc-2::before
                            left: -15px
                            width: 30px
                        &.arc-3::before
                            left: -10px
                            width: 25px
                div.group-line-container
                    position: absolute
                    top: 0
                    display: flex
                    flex-direction: column
                    justify-content: flex-end
                    width: 35px
                    height: calc(100% + 1px)
                    &.container-1
                        left: -20px
                    &.container-2
                        left: -15px
                    &.container-3
                        left: -10px
                    span
                        display: inline-block
                        height: 100%
                        border-left: 1px solid $brand-secondary-color
                        &.line-1
                            left: -20px
                        &.line-2
                            left: -15px
                        &.line-3
                            left: -10px
                td:nth-child(11)
                    vertical-align: top
/* Header controls */
th
    position: relative
    .duplicate
        position: absolute
        background-color: #fff
        z-index: 999
    .control
        position: absolute
        top: 0
        right: 0
        margin: 0 12px
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        img
            cursor: pointer
        &.arrow-in-circle
            margin: 0
            margin-right: -11px
            span
                display: inline-block
                padding: 2px
                color: $gray-500-color!important
                font-size: 16px
                border: 1px solid $gray-300-color
                border-radius: 20px
                background-color: #fff
                cursor: pointer
    &.quarter
        padding: 0 !important
        .cell-container
            display: flex
            flex-direction: column
            height: 68px
            .block
                position: relative
                height: 50%
                &:nth-child(1)
                    border-bottom: 1px solid $gray-300-color
                    background-color: #fff
                    .overlay
                        width: 100%
                        height: 100%
                        text-align: right
                        &::after
                            content: ''
                            position: absolute
                            width: 2px
                            height: 100%
                            background-color: #fff
                &:nth-child(2)
                    display: flex
                    justify-content: flex-end
                    .title
                        display: flex
                        flex-direction: column
                        justify-content: center
                        padding: 0 10px
                &.sum
                    display: flex
                    flex-direction: column
                    justify-content: center
                    padding-right: 10px

/* Indicator controls && line values */
tr.row-indicator
    .group-cell-1, .group-cell-2
        position: relative
        vertical-align: top
    .group-cell-1
        min-width: 200px
        padding-top: 36px!important
        .controls
            padding: 12px 14px
            justify-content: space-between
            img
                margin-right: -3px
                margin-bottom: -3px
    .group-cell-2
        padding-top: 26px!important
        .controls
            padding: 2px
            justify-content: flex-end
            img.frame
                filter: invert(77%) sepia(0%) saturate(283%) hue-rotate(179deg) brightness(82%) contrast(95%)
            img.robot
                filter: invert(69%) sepia(29%) saturate(403%) hue-rotate(72deg) brightness(96%) contrast(95%)
    .controls
        box-sizing: border-box
        position: absolute
        top: 0
        left: 0
        display: flex
        width: 100%
        color: $gray-500-color
        .title
            span
                padding: 0 6px
                color: $gray-500-color
                background-color: $gray-100-color
                border-radius: $border-radius
        .values
            span:nth-child(n)
                margin-right: 5px
            .unit-measure
                padding: 0 6px
                color: $gray-500-color
                background-color: $gray-100-color
                border-radius: $border-radius
            .value_type, .calculation_type
                display: inline-block
                width: 15px
                color: $gray-500-color
                text-align: center
                border: 1px solid $gray-500-color
                border-radius: $border-radius
        .indication
            margin: 3px
            width: 10px
            height: 10px
            border-radius: 100%
tr
    .lines
        list-style-type: none
        margin: 0
        padding: 0
        li
            padding-bottom: 6px
        &.data-values
            li:first-child
                font-weight: 500

mark
    background-color: $search-text-highlight

.control.flex
    display: flex
    .label-container
        flex-direction: column
        justify-content: center
        margin: 0
        label
            padding: 0 4px
            font-weight: 400
            font-size: 14px

#altimeter
    z-index: 9999
    .delimiter
        .scale-container
            &::after
                content: ''
                position: absolute
                display: inline-block
                width: 200px
                height: 1px
                background-color: red

@keyframes collapsed
    from
        width: 55px
    to
        width: 25px
</style>
