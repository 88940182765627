<template>
    <modal :title="modalTitle" @save="create">
        <div class="control" v-if="noGroup">
            <div class="label-container">
                <span>Направление</span>
            </div>
            <select-direction @select="selectDirection"/>
        </div>
        <div class="control" v-else>
            <div class="label-container">
                <span>Группа</span>
            </div>
            <select-group @select="selectGroup"/>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" v-model="targetTitle"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import SelectGroup from '../components/SelectGroup.vue';
import SelectDirection from '../components/SelectDirection.vue';


export default {
    name: 'CreateTargetModal',
    components: {
        Modal,
        SelectGroup,
        SelectDirection,
    },
    data() {
        return {
            modalTitle: 'Создать новую цель',
            targetTitle: '',
            group: null,
            direction: null,
            noGroup: false,
        }
    },
    methods: {
        ...mapActions([
            'createTarget',
        ]),
        selectGroup(group) {
            this.group = group;
        },
        selectDirection(direction) {
            this.direction = direction;
        },
        create() {
            if (this.targetTitle && (this.group || this.direction)) {
                this.$store.commit('setTarget', {
                    title: this.targetTitle,
                    parent_id: this.noGroup ? this.direction.id : this.group.id,
                    no_group: this.noGroup
                });
                this.createTarget();
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.noGroup = this.$parent.noGroup;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control:first-child
    margin-bottom: 20px
</style>
