<template>
    <div class="modal" @click.self="$emit('close')">
        <div class="modal-content">
            <div class="modal-header">
                <span class="close" @click.stop="$emit('close')">&times;</span>
                <div class="title">{{ title }}</div>
                <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <btn-container :small="true" :title="'Отмена'" @click.stop="$emit('close')"/>
                    <btn-container :primary="true" :small="true" :title="'Сохранить изменения'" @click.stop="$emit('save')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue'

export default {
    name: 'Modal',
    components: {
        BtnContainer,
    },
    props: ['title', 'subtitle'],
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.btn-group
    .btn-container
        &:first-child
            margin-right: 15px
            width: 100px
        &:last-child
            width: 150px

</style>
