<template>
    <div class="admin-oiv-management">
        <div class="card">
            <div class="body">
                <table>
                    <tbody>
                    <tr v-for="oiv in oivs">
                        <td>
                            <div class="radio-container" @click="selectOiv(oiv)">
                                <input type="radio" name="value-type" :checked="selected && selected.id === oiv.id"/>
                                <label>{{ oiv.title }}</label>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer">
                <div class="btn-group">
                    <btn-container
                        :primary="true"
                        :small="true"
                        :title="'Создать'"
                        @click="isCreateFormVisible = true"/>
                    <btn-container
                        :primary="selected && selected.id"
                        :small="true"
                        :title="'Редактировать'"
                        @click="showUpdateForm"/>
                </div>
            </div>
        </div>
        <create-form v-if="isCreateFormVisible" @create="create" @close="isCreateFormVisible = false"/>
        <update-form v-if="isUpdateFormVisible" @update="update" @close="isUpdateFormVisible = false"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import CreateForm from './CreateForm.vue';
import UpdateForm from './UpdateForm.vue';

export default {
    name: 'AdminOiv',
    components: {
        UpdateForm,
        BtnContainer,
        CreateForm
    },
    data() {
        return {
            title: '',
            selected: null,
            isCreateFormVisible: false,
            isUpdateFormVisible: false,
        }
    },
    computed: {
        ...mapGetters([
            'oivs',
        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'createOiv',
            'updateOiv',
        ]),
        selectOiv(oiv) {
            this.selected = oiv;
        },
        showUpdateForm() {
            if (this.selected) {
                this.selected = this.oivs.find(oiv => oiv.id === this.selected.id);
                if (this.selected && this.selected.id) {
                    this.isUpdateFormVisible = true;
                }
            }
        },
        create(title) {
            this.$store.commit('setSelectedOiv', { title });
            this.createOiv();
            this.isCreateFormVisible = false;
        },
        update(title) {
            this.$store.commit('setSelectedOiv', {
                id: this.selected.id,
                title,
            });
            this.updateOiv();
            this.isUpdateFormVisible = false;
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.admin-oiv-management
    padding-top: 25px

.card
    .body
        font-size: small
    .footer
        border: none
        .btn-group
            display: flex
            .btn-container
                width: 120px
            .btn-container:first-child
                margin-right: 15px

.radio-container
    label
        padding-bottom: 4px

</style>
