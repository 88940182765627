<template>
    <div class="email-notification">
        <ul class="notification-list">
            <li v-for="notification in emailNotifications">
                <div class="card" :class="{ disabled: !notification.config.isActive }">
                    <div class="header">
                        <div class="title">{{ notification.title }}</div>
                    </div>
                    <div class="body">
                        <table>
                            <tbody>
                            <tr>
                                <td>Тема письма:</td>
                                <td>{{ notification.config.subject }}</td>
                            </tr>
                            <tr class="notification content">
                                <td>Сообщение:</td>
                                <td v-html="notification.config.message"></td>
                            </tr>
                            <template v-if="notification.config.dates">
                                <tr class="notification dates">
                                    <td>Дата уведомления:</td>
                                    <td>
                                        <ul>
                                            <li v-for="dateString in notification.config.dates">{{ formatDate(dateString) }}</li>
                                        </ul>
                                    </td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="footer">
                        <div class="btn-group">
                            <btn-container
                                :primary="notification.config.isActive"
                                :small="true"
                                :title="notification.config.isActive ? 'Приостановить' : 'Возобновить'"
                                @click="toggleNotificationStatus(notification)"/>
                            <btn-container
                                :primary="true"
                                :small="true"
                                :title="'Редактировать'"
                                @click="showEditForm(notification)"/>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <edit-form v-if="isEditFormVisible" :notification="selectedNotification" @cancel="isEditFormVisible = false" @update="update"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import EditForm from '@/index/components/Admin/EmailNotification/EditForm.vue';
import dateformat from 'dateformat';
import { i18n } from "dateformat";

i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
i18n.monthNames = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

export default {
    components: {
        EditForm,
        BtnContainer,
    },
    data() {
        return {
            isEditFormVisible: false,
            selectedNotification: null,
        }
    },
    computed: {
        ...mapGetters([
            'emailNotifications',
        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'updateEmailNotification',
        ]),
        formatDate(dateString) {
            return dateformat(dateString, 'yyyy-mm-dd HH:MM');
        },
        showEditForm(notification) {
            this.selectedNotification = notification;
            this.isEditFormVisible = true;
        },
        update(data) {
            this.isEditFormVisible = false;
            this.selectedNotification.config = Object.assign({}, this.selectedNotification.config, data);
            this.$store.commit('setSelectedEmailNotification', this.selectedNotification);
            this.updateEmailNotification();
        },
        toggleNotificationStatus(notification) {
            notification.config.isActive = !notification.config.isActive;
            this.$store.commit('setSelectedEmailNotification', notification);
            this.updateEmailNotification();
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

ul
    list-style-type: none
    margin: 0
    padding: 0

.notification-list
    margin-top: 25px
    > li
        margin-bottom: 10px

table
    border-collapse: collapse
    width: 100%
    tr
        td
            padding: 5px
        td:first-child
            width: 150px
            padding-right: 20px
            text-align: center
            vertical-align: top

.material-icons-outlined.rotate
    animation-name: rotate
    animation-duration: .3s
    animation-fill-mode: forwards

.card
    .body
        font-size: small
    .footer
        border: none
        .btn-group
            display: flex
            .btn-container
                width: 120px
            .btn-container:first-child
                 margin-right: 15px
.card.disabled
    background-color: $gray-300-color

@keyframes rotate
    from
        transform: rotate(0)
    to
        transform: rotate(180deg)
</style>
