<template>
    <tooltip-filter :id="id" :coordinates="coordinates" :with-footer="true" @apply="apply">
        <ul class="tooltip-indicator-list custom-scrollbar">
            <li>
                <div class="control">
                    <div class="checkbox-container" @click="toggleAll">
                        <input type="checkbox" :checked="selected.length === oivs.length"/>
                        <label>Все ОИВ</label>
                    </div>

                </div>
            </li>
            <li v-for="oiv in oivs">
                <div class="control">
                    <div class="checkbox-container" @click="toggle(oiv)">
                        <input type="checkbox" :checked="selected.some(o => o.id === oiv.id)"/>
                        <label>{{ oiv.title }}</label>
                    </div>
                </div>
            </li>
        </ul>
    </tooltip-filter>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '../components/ReusableComponents/TooltipFilter.vue';

export default {
    name: 'TooltipOivFilter',
    components: {
        TooltipFilter,
    },
    props: ['coordinates'],
    data() {
        return {
            id: 'tooltip-oiv-filter',
            selected: [],
        }
    },
    computed: {
        ...mapGetters([
            'oivs',
            'filteredOivs',
        ]),
    },
    watch: {
        filteredOivs() {
            this.selected = this.filteredOivs;
        },
    },
    methods: {
        toggleAll() {
            if (this.selected.length < this.oivs.length) {
                this.selected = this.oivs;
            } else {
                this.selected = [];
            }
        },
        toggle(oiv) {
            if (this.selected.some(o => o.title === oiv.title)) {
                this.selected = this.selected.filter(o => o.title !== oiv.title);
            } else {
                this.selected = this.selected.concat(oiv);
            }
        },
        apply() {
            if (this.selected.length) {
                this.$store.commit('setFilteredOivs', this.selected);
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.selected = this.filteredOivs;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.tooltip-indicator-list
    max-height: 300px
    margin: 4px 2px 4px 0
    padding: 20px 0 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    li
        min-width: 220px
        margin-right: 15px
        padding: 12px 0
        border-bottom: 1px solid $gray-200-color
        &:first-child
            padding-top: 0
        &:last-child
            border-bottom: none
        .control
            display: flex
            input
                display: inline-block
                margin-right: 8px
                width: auto
            label
                display: inline-block
                max-width: 260px
                font-size: $font-size
                font-weight: 500

</style>
