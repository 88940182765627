<template>
    <div class="preloader">
        <div class="process">
            <div class="loader" v-if="isProcessing">
                <spinner/>
            </div>
            <!--<div class="user-auth-fail" v-if="isFailed">-->
                <!--<div class="header">Не удалось авторизовать пользователя</div>-->
                <!--<div class="btn-group">-->
                    <!--<btn-container :title="'Продолжить \n (без доступа к функционалу редактирования)'" @click="$router.push({ name: 'StrategicDashboard' })"/>-->
                    <!--<btn-container :primary="true" :title="'Обновить страницу'" @click="reload"/>-->
                <!--</div>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '../components/ReusableComponents/BtnContainer.vue';
import Spinner from '../components/Greetings/Spinner.vue';

export default {
    components: {
        BtnContainer,
        Spinner,
    },
    data() {
        return {
            isProcessing: true,
            // isFailed: false,
        }
    },
    computed: {
        ...mapGetters([

        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'checkUser',
        ]),
        // reload() {
        //     window.top.location.reload();
        // }
    },
    created() {
        this.$store.commit('setUserSign', this.$route.query.userSign);
        this.checkUser()
            .then(() => {
                this.isProcessing = false;
                this.$router.push({ name: 'StrategicDashboard' })
            })
            .catch(error => {
                this.isProcessing = false;
                // this.isFailed = true;
                this.$router.push({ name: 'StrategicDashboard' })
            });
    },
    mounted() {

    }
}
</script>

<style lang="sass" scoped>
@import '../assets/index.sass'

.preloader
    margin-top: 150px
    margin-left: auto
    margin-right: auto
    max-width: 50%
    text-align: center
    /*opacity: .7*/
    .process
        display: flex
        flex-direction: column
        .user-auth-fail
            .header
                margin-bottom: 30px
                font-size: 20px

.btn-container
    width: 250px

.btn-group
    .btn-container
        &:first-child
            margin-right: 15px
</style>
