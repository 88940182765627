<template>
    <div class="select-container" ref="container">
        <div class="input-container" @click="toggleTooltip">
            <label>Выберите из списка</label>
            <input type="text" :value="calculated"/>
            <div class="control-elements">
                <div class="arrows">
                    <div>
                        <template v-if="isOptionsContainerVisible">
                            <img src="~@/index/assets/icons/chevron_up.svg"/>
                        </template>
                        <template v-else>
                            <img src="~@/index/assets/icons/chevron_down.svg"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="tooltipCoordinates">
            <tooltip-filter
                :id="id"
                :coordinates="tooltipCoordinates"
                :with-footer="true"
                @apply="apply"
                @close="cancel">
                <ul class="tooltip-indicator-list custom-scrollbar">
                    <li v-for="quarter in quarters">
                        <div class="control">
                            <div class="radio-container" @click="toggle(quarter)">
                                <input type="radio" name="quarter" :checked="selected === quarter"/>
                                <label>{{ quarter.title }}</label>
                            </div>
                        </div>
                    </li>
                </ul>
            </tooltip-filter>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '../components/ReusableComponents/TooltipFilter.vue';

export default {
    name: 'SelectQuarters',
    props: ['value'],
    components: {
        TooltipFilter,
    },
    data() {
        return {
            id: 'tooltip-quarter-options',
            selected: null,
            origSelected: [],
            tooltipCoordinates: null,
            isOptionsContainerVisible: false,
            calculated: null,
        }
    },
    computed: {
        ...mapGetters([
            'quarters',
        ]),
    },
    watch: {
        value(v) {
            if (v) {
                this.selected = this.quarters.find(q => q.id === v);
            }
        },
        // selected() {
        //     this.calculated = this.selected ? this.selected.title : '';
        // }
    },
    methods: {
        toggleTooltip() {
            if (this.isOptionsContainerVisible) {
                this.tooltipCoordinates = null;
            } else {
                const parentPos = this.$refs.container.getBoundingClientRect();
                this.tooltipCoordinates = {
                    top: parentPos.bottom,
                    left: parentPos.left
                };
            }
            this.isOptionsContainerVisible = !this.isOptionsContainerVisible;
        },
        toggle(quarter) {
            this.selected = quarter;
        },
        apply() {
            this.calculated = this.selected.title;
            this.$emit('select', this.selected);
            this.isOptionsContainerVisible = false;
            this.tooltipCoordinates = null;
        },
        cancel() {
            this.selected = this.value;
            this.isOptionsContainerVisible = false;
            this.tooltipCoordinates = null;
        }
    },
    mounted() {
        if (this.value) {
            this.selected = this.value;
            this.calculated = this.selected.title;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.tooltip-indicator-list
    width: 200px
    max-height: 300px
    margin: 20px 2px 20px 0
    padding: 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    li
        margin-right: 15px
        padding: 15px 0
        border-bottom: 1px solid $gray-200-color
        &:first-child
            padding-top: 0
        &:last-child
            padding-bottom: 0
            border-bottom: none
        .control
            display: flex
            label
                max-width: 260px

</style>
