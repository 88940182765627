<template>
    <modal :title="modalTitle" @save="save" @close="$emit('close')">
        <div class="section">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <input type="text" v-model="title"/>
            </div>
        </div>
        <div class="section">
            <div class="label-container">
                <span>Период</span>
            </div>
            <div class="text-container">
                <flat-pickr v-model="date" :config="config" @on-close="saveDates"></flat-pickr>
            </div>
        </div>
        <div class="section">
            <div class="select-group">
                <div class="label-container">
                    <span>Отчетный квартал</span>
                </div>
                <select-quarters :value="quarter" @select="assignQuarter"/>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import { ref, onMounted } from 'vue';
import Modal from '@/index/components/ReusableComponents/Modal.vue';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import SelectQuarters from '@/index/components/SelectQuarters.vue';
import FlatPickr from 'vue-flatpickr-component';
import dateformat from 'dateformat';
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/flatpickr.css';
import { i18n } from "dateformat";


i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
i18n.monthNames = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

export default {
    name: 'DatepickerModal',
    components: {
        Modal,
        BtnContainer,
        FlatPickr,
        SelectQuarters,
    },
    data() {
        return {
            modalTitle: null,
            title: '',
            date: null,
            config: {
                mode: 'range',
                locale: Russian,
                dateFormat: 'j F',
                defaultDate: []
            },
            from: null,
            to: null,
            quarter: null,
        }
    },
    computed: {
        ...mapGetters([
            'quarters',
        ]),
    },
    methods: {
        ...mapActions([
            'createInterval',
            'updateInterval',
        ]),
        saveDates(dates) {
            this.from = dateformat(dates[0], 'yyyy-mm-dd');
            this.to = dateformat(dates[1], 'yyyy-mm-dd');
        },
        assignQuarter(quarter) {
            this.quarter = quarter;
        },
        save() {
            if (this.title && this.from && this.to && this.quarter) {
                const interval = {
                    id: this.id,
                    title: this.title,
                    config: {
                        from: this.from,
                        to: this.to,
                    },
                    external_type: 'quarter',
                    external_link_id: this.quarter.id,
                };
                this.$emit('save', interval);
            }
        },
    },
    created() {
        if (this.$parent.selectedInterval) {
            this.id = this.$parent.selectedInterval.id;
            this.title = this.$parent.selectedInterval.title;
            this.from = new Date(this.$parent.selectedInterval.config.from);
            this.to = new Date(this.$parent.selectedInterval.config.to);
            this.config.defaultDate = [ this.from, this.to ];
            this.quarter = this.quarters.find(q => q.id === this.$parent.selectedInterval.external_link_id);
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.btn-container
    max-width: 150px

table
    border-collapse: collapse
    width: 100%
    th
        padding: 10px 20px
        font-size: 12px
        font-weight: normal
        text-align: left
    td
        padding: 10px 20px
        font-size: 12px
        border-bottom: 2px solid #fff
        background-color: $blue-light

.section
    margin-bottom: 20px

.flatpickr-input
    border: none
    background-color: inherit
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    outline: none
</style>
