<template>
    <div class="result-step">
        Обновлено значений: {{ data.imported }}
        <p>Обнаружено ошибок: {{ errors.length }}</p>
        <table v-if="errors.length">
            <tbody>
            <tr>
                <td>Номер строки</td>
                <td>Информация об ошибке</td>
                <template v-for="field in fields">
                    <td>{{ field.value }}</td>
                </template>
            </tr>
            <tr v-for="error in errors">
                <td>{{ error.row.number }}</td>
                <td>{{ error.message }}</td>
                <template v-for="field in error.row.fields">
                    <td>{{ field.value }}</td>
                </template>
            </tr>
            </tbody>
        </table>
        <div class="control">
            <btn-container
                    :primary="true"
                    :small="true"
                    :title="'Отправить уведомление'"
                    :icon="'add'"
                    @click="notify"
            />
            <btn-container
                    :primary="true"
                    :small="true"
                    :title="'Начать заново'"
                    :icon="'add'"
                    @click="submit"
            />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';

export default {
    components: {
        BtnContainer,
    },
    props: ['data'],
    data() {
        return {
            errors: this.data.errors || [],
            fields: Object.entries(this.data.uploadConfig)
                .filter(pair => {
                    const key = pair[0];
                    return key.includes('col');
                }).map(pair => {
                    return {
                        key: pair[0],
                        value: pair[1]
                    }
                })
        }
    },
    computed: {
        ...mapGetters([

        ]),
    },
    watch: {

    },
    methods: {
        ...mapActions([
            'notifyProcessImportResult'
        ]),
        notify() {
            this.notifyProcessImportResult();
        },
        submit() {
            this.$emit('commit');
        }
    },
    created() {

    },
    mounted() {

    }
}
</script>

<style lang="sass" scoped>
@import '../../../../assets/index.sass'

.result-step
    margin-top: 10px
    .header
        font-size: large
    .control
        max-width: 240px
        margin-top: 10px

table
    border-collapse: collapse
    width: 100%
    max-width: 1000px
    th
        padding: 10px 20px
        font-size: 12px
        font-weight: normal
        text-align: left
    td
        padding: 10px 20px
        font-size: 12px
        border-bottom: 2px solid #fff
        background-color: $blue-light
</style>
