<template>
    <modal :title="title" @save="save">
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" ref="title" v-model="directionTitle"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import Modal from '@/index/components/ReusableComponents/Modal.vue';

export default {
    name: 'CreateDirectionModal',
    components: {
        BtnContainer,
        Modal,
    },
    data() {
        return {
            title: 'Создать новое направление',
            directionTitle: '',
        }
    },
    methods: {
        ...mapActions([
            'createDirection',
        ]),
        save() {
            if (this.directionTitle) {
                this.$store.commit('setDirection', { title: this.directionTitle });
                this.createDirection();
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.$refs.title.focus();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

</style>
