<template>
    <info-modal @close="close" >
        <div class="info">
            Фильтр успешно сохранен.
        </div>
    </info-modal>
</template>

<script>
import InfoModal from '../components/ReusableComponents/InfoModal.vue';

export default {
    name: 'SaveFilterResultModal',
    components: {
        InfoModal,
    },
    data() {
        return {

        }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

</style>
