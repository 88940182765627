<template>
    <modal :title="title" :subtitle="subtitle" @save="save">
        <div class="control">
            <div class="label-container">
                <span>Плановое значение</span>
            </div>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="planValue" @blur="formatValue"/>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import FormatDataHelper from '../Helpers/Formatter';

export default {
    name: 'EditModalNextPeriod',
    components: {
        Modal,
    },
    data() {
        return {
            indicatorValues: null,
            title: `Редактировать данные показателя за ${this.$parent.selectedPeriod.title}`,
            subtitle: this.$parent.selectedIndicator.title,
            planValue: null,
        }
    },
    computed: {
        ...mapGetters([
            'periods',
            'user',
            'dateAccessOivStatus',
        ]),
    },
    methods: {
        ...mapActions([
            'createIndicatorValues',
            'updateIndicatorValues',
        ]),
        formatValue() {
            this.planValue = FormatDataHelper.localize(this.planValue);
        },
        save() {
            const data = {
                indicator_id: this.$parent.selectedIndicator.id,
                period_id: this.$parent.selectedPeriod.id,
                plan: this.planValue ? FormatDataHelper.normalize(this.planValue) : null,
                type: 'year',
            };
            if (this.indicatorValues) {
                data.id = this.indicatorValues.id;
            }
            this.$store.commit('setIndicatorValues', data);
            if (this.indicatorValues) {
                this.updateIndicatorValues();
            } else {
                this.createIndicatorValues();
            }
            this.$emit('close');
        }
    },
    mounted() {
        this.indicatorValues = this.$parent.selectedIndicator.periods.find(p => p.period_id === this.$parent.selectedPeriod.id);
        if (this.indicatorValues) {
            this.planValue = FormatDataHelper.localize(this.indicatorValues.plan);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 20px
    &:last-child
        margin-bottom: 0
    .input-group-container
        display: flex
        .input-container
            flex-grow: 1
            &:first-child
                margin-right: 15px
                input
                    &:disabled
                        color: $gray-900-color
                        background-color: $gray-200-color
    span
        font-weight: 600
        font-size: $font-size
        &.action
            color: $brand-primary-color
    .label-container
        display: flex
        margin-bottom: 8px
        justify-content: space-between

</style>
