<template>
    <modal :title="modalTitle" @save="update">
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" v-model="directionTitle"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';

export default {
    name: 'EditDirectionModal',
    components: {
        Modal,
    },
    data() {
        return {
            modalTitle: 'Редактировать направление',
            directionTitle: '',
        }
    },
    methods: {
        ...mapActions([
            'updateDirection',
        ]),
        update() {
            if (this.directionTitle && this.directionTitle !== this.$parent.selectedIndicator.title) {
                this.$store.commit('setDirection', {
                    id: this.$parent.selectedIndicator.id,
                    title: this.directionTitle,
                });
                this.updateDirection();
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.directionTitle = this.$parent.selectedIndicator.title;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 40px
    &:last-child
        margin-bottom: 0
    span
        font-weight: 600
        font-size: $font-size
    .input-container
        margin-top: 16px

</style>
