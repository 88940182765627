<template>
    <tooltip-filter :id="id" :coordinates="coordinates" :with-header="true" :with-footer="true" @apply="apply">
        <template v-slot:header>
            <div class="control">
                <div class="input-container search">
                    <img src="~@/index/assets/icons/search.svg"/>
                    <input type="text" placeholder="Поиск" v-model="search"/>
                </div>
            </div>
        </template>
        <ul class="tooltip-indicator-list custom-scrollbar">
            <li>
                <div class="control">
                    <div class="checkbox-container" @click="toggleAll">
                        <input type="checkbox" :checked="selected.length === indicators.length"/>
                        <label>Все показатели</label>
                    </div>
                </div>
            </li>
            <li v-for="indicator in searched">
                <template v-if="filteredData.some(i => i.id === indicator.id)">
                    <div class="control">
                        <div class="checkbox-container" @click="toggle(indicator)">
                            <input type="checkbox" :checked="selected.some(i => i.id === indicator.id)"/>
                            <label v-html="indicator.title"></label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="control">
                        <div class="checkbox-container disabled">
                            <label v-html="indicator.title"></label>
                        </div>
                    </div>
                </template>
            </li>
        </ul>
    </tooltip-filter>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '../components/ReusableComponents/TooltipFilter.vue'

export default {
    name: 'TooltipIndicatorFilter',
    components: {
        TooltipFilter,
    },
    props: ['coordinates'],
    data() {
        return {
            id: 'tooltip-indicator',
            search: '',
            searched: [],
            selected: [],
        }
    },
    computed: {
        ...mapGetters([
            'filteredData',
            'indicators',
            'filteredIndicators',
        ]),
    },
    watch: {
        indicators() {
            this.searched = this.indicators;
        },
        filteredIndicators() {
            this.selected = this.filteredIndicators;
        },
        search(s) {
            if (s) {
                const clonedSource = JSON.parse(JSON.stringify(this.indicators));
                const highlightRegExp = new RegExp(s, 'gi');
                this.searched = clonedSource.filter(i => {
                    if (i.title.toLowerCase().search(s.toLowerCase()) !== -1) {
                        i.title = i.title.replace(highlightRegExp, match => `<mark>${match}</mark>`);
                        return true;
                    }
                    return false;
                });
            } else {
                this.searched = this.indicators;
            }
        }
    },
    methods: {
        toggleAll() {
            if (this.selected.length < this.indicators.length) {
                this.selected = this.indicators;
            } else {
                this.selected = [];
            }
        },
        toggle(indicator) {
            if (this.selected.some(i => i.id === indicator.id)) {
                this.selected = this.selected.filter(i => i.id !== indicator.id);
            } else {
                this.selected = this.selected.concat(indicator);
            }
        },
        apply() {
            if (this.selected.length) {
                this.$store.commit('setFilteredIndicators', this.selected);
                this.$emit('close');
            }
        }
    },
    mounted() {
        this.searched = this.indicators;
        this.selected = this.filteredIndicators;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.tooltip-indicator-list
    max-height: 300px
    margin: 20px 2px 20px 0
    padding: 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    li
        margin-right: 15px
        padding: 12px 0
        border-bottom: 1px solid $gray-200-color
        &:first-child
            padding-top: 0
        &:last-child
            border-bottom: none
        .control
            display: flex
            input
                display: inline-block
                margin-right: 8px
                width: auto
            label
                display: inline-block
                max-width: 260px
                font-size: $font-size
                font-weight: 500

</style>
