<template>
    <div class="select-container" ref="container">
        <div class="input-container" @click="toggleTooltip">
            <label>Выберите из списка</label>
            <input type="text" v-model="calculatedValue"/>
            <div class="control-elements">
                <!--<div class="element">-->
                <!--<div>-->
                <!--<img class="more" src="~@/index/assets/icons/reset.svg"/>-->
                <!--</div>-->
                <!--</div>-->
                <div class="arrows">
                    <div>
                        <template v-if="isOptionsContainerVisible">
                            <img src="~@/index/assets/icons/chevron_up.svg"/>
                        </template>
                        <template v-else>
                            <img src="~@/index/assets/icons/chevron_down.svg"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="tooltipCoordinates">
            <tooltip-filter
                :id="id"
                :coordinates="tooltipCoordinates"
                :with-header="true"
                :with-footer="true"
                @apply="apply"
                @close="cancel">
                <template v-slot:header>
                    <div class="control">
                        <div class="input-container search">
                            <img src="~@/index/assets/icons/search.svg"/>
                            <input type="text" placeholder="Поиск" v-model="search"/>
                        </div>
                    </div>
                </template>
                <ul class="tooltip-indicator-list custom-scrollbar">
                    <li v-for="target in targets">
                        <div class="control">
                            <div class="radio-container" @click="toggle(target)">
                                <input type="radio" name="target" :checked="selected.id === target.id"/>
                                <label v-html="target.title"></label>
                            </div>
                        </div>
                    </li>
                </ul>
            </tooltip-filter>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '../components/ReusableComponents/TooltipFilter.vue';

export default {
    name: 'SelectTarget',
    props: ['value'],
    components: {
        TooltipFilter,
    },
    data() {
        return {
            isOptionsContainerVisible: false,
            tooltipCoordinates: null,
            id: 'tooltip-target-options',
            search: '',
            searched: [],
            selected: [],
            origSelected: [],
            calculatedValue: '',
        }
    },
    computed: {
        ...mapGetters([
            'targets',
        ]),
    },
    watch: {
        value(target) {
            if (target) {
                this.selected = target;
                this.calculateValue();
            }
        },
        groups() {
            // this.searched = this.directions;
            // this.selected = this.directions;
            // this.calculateValue();
        },
        search(s) {
        //     if (s) {
        //         const clonedSource = JSON.parse(JSON.stringify(this.directions));
        //         const highlightRegExp = new RegExp(s, 'gi');
        //         this.searched = clonedSource.filter(i => {
        //             if (i.title.toLowerCase().search(s.toLowerCase()) !== -1) {
        //                 i.title = i.title.replace(highlightRegExp, (match) => `<mark>${match}</mark>`);
        //                 return true;
        //             }
        //             return false;
        //         })
        //     } else {
        //         this.searched = this.directions;
        //     }
        },
    },
    methods: {
        toggleTooltip() {
            if (this.isOptionsContainerVisible) {
                this.tooltipCoordinates = null;
            } else {
                const parentPos = this.$refs.container.getBoundingClientRect();
                this.tooltipCoordinates = {
                    top: parentPos.bottom,
                    left: parentPos.left
                };
            }
            this.isOptionsContainerVisible = !this.isOptionsContainerVisible;
        },
        toggle(target) {
            this.selected = target;
        },
        apply() {
            this.calculateValue();
            this.$emit('select', this.selected);
            this.isOptionsContainerVisible = false;
            this.tooltipCoordinates = null;
        },
        calculateValue() {
            this.calculatedValue = this.selected.title;
        },
        cancel() {
            // this.selected = this.origSelected;
            this.isOptionsContainerVisible = false;
            this.tooltipCoordinates = null;
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.tooltip-indicator-list
    max-height: 300px
    margin: 20px 2px 20px 0
    padding: 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    li
        margin-right: 15px
        padding: 15px 0
        border-bottom: 1px solid $gray-200-color
        &:first-child
            padding-top: 0
        &:last-child
            padding-bottom: 0
            border-bottom: none
        .control
            display: flex
            label
                max-width: 260px

</style>
