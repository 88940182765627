module.exports = {
    data: [{
        type: 'direction',
        title: 'Экономика Москвы ориентирована на развитие отраслей с высокой добавленной стоимостью',
        id: 1,
        parent_id: null,
        path_id: '1',
        node: 4,
        order: 1,
        order_title: 'Направление № 1',
        is_displayed: true,
        periods: null,
    }, {
        type: 'group',
        title: 'Развитие инвестиционного потенциала Москвы',
        id: 2,
        parent_id: 1,
        path_id: '1/2',
        node: 3,
        order: 1,
        order_title: 'Группа',
        is_displayed: true,
        periods: null,
    }, {
        type: 'target',
        title: 'Стимулировать рост инвестиций в основной капитал',
        id: 3,
        parent_id: 2,
        path_id: '1/2/3',
        node: 2,
        order: 1,
        order_title: 'Цель 1.1',
        is_displayed: true,
        periods: null,
    }, {
        type: 'indicator',
        title: 'Рост инвестиций в основной капитал, в т.ч. предприятий обрабатывающей промышленности, млрд руб.',
        id: 4,
        parent_id: 3,
        path_id: '1/2/3/4',
        node: 1,
        order: 1,
        order_title: 'Показатель 1.1 - 1',
        is_displayed: true,
        unit_measure: 'млрд руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 4000, fact: 4000, calculated: 100 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 4400, fact: 4400, calculated: 100 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 600, fact: 600, calculated: 100 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 1500, fact: 1500, calculated: 100 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 2600, fact: 2600, calculated: 100 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 4400, fact: 4400, calculated: 100 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 4800 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: '',
    }, {
        type: 'indicator',
        title: 'Рост инвестиций в основной капитал предприятий обрабатывающей промышленности, млрд руб.',
        id: 5,
        parent_id: 3,
        path_id: '1/2/3/5',
        node: 1,
        order: 2,
        order_title: 'Показатель 1.1 - 2',
        is_displayed: true,
        unit_measure: 'млрд руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 110, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 125, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 25, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 40, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 75, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 125, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 140 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Рост частных инвестиций по заключенным контрактам, млрд руб.',
        id: 6,
        parent_id: 3,
        path_id: '1/2/3/6',
        node: 1,
        order: 3,
        order_title: 'Показатель 1.1 - 3',
        is_displayed: true,
        unit_measure: 'млрд руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 109, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 126, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 7.6, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 27.5, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 54.5, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 126, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 152 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Рост привлеченных инвестиций на 1 руб. оказанной поддержки в сфере промышленности, руб.',
        id: 7,
        parent_id: 2,
        path_id: '1/2/3/7',
        node: 1,
        order: 4,
        order_title: 'Показатель 1.1 - 4',
        is_displayed: true,
        unit_measure: 'руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 5, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 6, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 0, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 0, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 0, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 6, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 7 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'target',
        title: 'Развивать неиспользуемые / неэффективно используемые территории',
        id: 8,
        parent_id: 2,
        path_id: '1/2/8',
        node: 1,
        order: 5,
        order_title: 'Цель 1.2',
        is_displayed: true,
        periods: null,
        oiv: null,
        comment: null,
    }, {
        type: 'indicator',
        title: 'Количество проектов КРТ, шт.',
        id: 9,
        parent_id: 8,
        path_id: '1/2/8/9',
        node: 1,
        order: 1,
        order_title: 'Показатель 1.2 - 1',
        is_displayed: true,
        unit_measure: 'шт.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 11, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 74, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 22, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 34, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 48, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 74, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 109 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Площадь тер. КРТ, га., нарастающим итогом',
        id: 10,
        parent_id: 8,
        path_id: '1/2/8/10',
        node: 1,
        order: 2,
        order_title: 'Показатель 1.2 - 2',
        is_displayed: true,
        unit_measure: 'млрд.руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 174, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 1194, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 314, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 485, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 622, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 1194, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 1719 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }],
    indicators: [{
        type: 'indicator',
        title: 'Рост инвестиций в основной капитал, в т.ч. предприятий обрабатывающей промышленности, млрд руб.',
        id: 4,
        parent_id: 3,
        path_id: '1/2/3/4',
        node: 1,
        order: 1,
        order_title: 'Показатель 1.1 - 1',
        is_displayed: true,
        unit_measure: 'млрд руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 4000, fact: 4000, calculated: 100 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 4400, fact: 4400, calculated: 100 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 600, fact: 600, calculated: 100 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 1500, fact: 1500, calculated: 100 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 2600, fact: 2600, calculated: 100 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 4400, fact: 4400, calculated: 100 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 4800 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: '',
    }, {
        type: 'indicator',
        title: 'Рост инвестиций в основной капитал предприятий обрабатывающей промышленности, млрд руб.',
        id: 5,
        parent_id: 3,
        path_id: '1/2/3/5',
        node: 1,
        order: 2,
        order_title: 'Показатель 1.1 - 2',
        is_displayed: true,
        unit_measure: 'млрд руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 110, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 125, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 25, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 40, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 75, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 125, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 140 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Рост частных инвестиций по заключенным контрактам, млрд руб.',
        id: 6,
        parent_id: 3,
        path_id: '1/2/3/6',
        node: 1,
        order: 3,
        order_title: 'Показатель 1.1 - 3',
        is_displayed: true,
        unit_measure: 'млрд руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 109, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 126, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 7.6, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 27.5, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 54.5, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 126, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 152 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Рост привлеченных инвестиций на 1 руб. оказанной поддержки в сфере промышленности, руб.',
        id: 7,
        parent_id: 2,
        path_id: '1/2/3/7',
        node: 1,
        order: 4,
        order_title: 'Показатель 1.1 - 4',
        is_displayed: true,
        unit_measure: 'руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 5, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 6, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 0, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 0, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 0, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 6, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 7 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Количество проектов КРТ, шт.',
        id: 9,
        parent_id: 8,
        path_id: '1/2/8/9',
        node: 1,
        order: 1,
        order_title: 'Показатель 1.2 - 1',
        is_displayed: true,
        unit_measure: 'шт.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 11, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 74, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 22, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 34, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 48, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 74, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 109 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }, {
        type: 'indicator',
        title: 'Площадь тер. КРТ, га., нарастающим итогом',
        id: 10,
        parent_id: 8,
        path_id: '1/2/8/10',
        node: 1,
        order: 2,
        order_title: 'Показатель 1.2 - 2',
        is_displayed: true,
        unit_measure: 'млрд.руб.',
        value_type: 'Численный',
        calculation_type: 'numLprop',
        sum_calculation_type: 'progress',
        periods: [{
            title: '2021',
            value: 2021,
            data: { plan: 174, fact: 0, calculated: 0 },
            quarters: null,
        }, {
            title: '2022',
            value: 2022,
            data: { plan: 1194, fact: 0, calculated: 0 },
            quarters: [{
                title: 'I',
                value: 1,
                data: { plan: 314, fact: 0, calculated: 0 },
            }, {
                title: 'II',
                value: 2,
                data: { plan: 485, fact: 0, calculated: 0 },
            }, {
                title: 'III',
                value: 3,
                data: { plan: 622, fact: 0, calculated: 0 },
            }, {
                title: 'IV',
                value: 4,
                data: { plan: 1194, fact: 0, calculated: 0 },
            }]
        }, {
            title: '2023',
            value: 2023,
            data: { plan: 1719 },
            quarters: null,
        }],
        oiv: 'ДИПП',
        comment: null,
    }],
    directions: [
        {
            title: 'Экономика Москвы ориентирована на развитие приоритетных отраслей, связанных с наукоемкими, высокотехнологичными секторами экономики с высоким экспортным потенциалом',
            id: 1,
        },
        {
            title: 'Развитие информированности о деятельности Комплекса и формирование его положительного медийного образа',
            id: 13,
        }
    ],
    groups: [
        {
            title: 'Инвестиционный потенциал Москвы',
            id: 2,
        },
        {
            title: 'ОЭЗ "Технополис "Москва""',
            id: 14,
        }
    ],
    targets: [
        {
            title: 'Обеспечить рост инвестиций в основной капитал, в том числе за счет реализации проектов Комплекса',
            id: 3,
        },
        {
            title: 'Организовать системную работу с контрагентами ДИПП (промышленные предприятия, эксперты, инвесторы - сбор информации + привлечение инвестиций',
            id: 9,
        },
        {
            title: 'Создать единую платформу мер поддержки, предлагаемых в Москве, механизм оценки их востребованности и мониторинга эффективности',
            id: 15,
        }
    ],
    oivs: [
        { title: 'ДИПП' },
        { title: 'ГБУ "АЦ"' },
        { title: 'ГИН' },
        { title: 'Пресс-служба Комплекса' },
        { title: 'ДГИ' },
        { title: 'ДКП' },
        { title: 'ДЭПиР' },
        { title: 'ОЭЗ' },
        { title: 'ЦРСВ' },
    ],
    periods: [{
        title: '2021',
        value: 2021,
        quarters: null,
        index: 0,
    }, {
        title: '2022',
        value: 2022,
        quarters: [{
            title: 'I',
            value: 1,
            index: 0,
        }, {
            title: 'II',
            value: 2,
            index: 1,
        }, {
            title: 'III',
            value: 3,
            index: 2,
        }, {
            title: 'IV',
            value: 4,
            index: 3,
        }],
        index: 1,
    }, {
        title: '2023',
        value: 2023,
        quarters: null,
        index: 2,
    }],
    quarters: [{
        title: 'I',
        value: 1,
        index: 0,
    }, {
        title: 'II',
        value: 2,
        index: 1,
    }, {
        title: 'III',
        value: 3,
        index: 2,
    }, {
        title: 'IV',
        value: 4,
        index: 3,
    }],
};