<template>
    <modal :title="modalTitle" @save="create">
        <div class="control">
            <div class="label-container">
                <span>Направление</span>
            </div>
            <select-direction @select="selectDirection"/>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Название</span>
            </div>
            <div class="text-container">
                <label>Введите название</label>
                <textarea class="custom-scrollbar" v-model="groupTitle"></textarea>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import SelectDirection from '../components/SelectDirection.vue';

export default {
    name: 'CreateGroupModal',
    components: {
        SelectDirection,
        Modal,
    },
    data() {
        return {
            modalTitle: 'Создать новую группу',
            groupTitle: '',
            direction: null,
        }
    },
    methods: {
        ...mapActions([
            'createGroup',
        ]),
        selectDirection(direction) {
            this.direction = direction;
        },
        create() {
            if (this.groupTitle && this.direction) {
                this.$store.commit('setGroup', { title: this.groupTitle, parent_id: this.direction.id });
                this.createGroup();
                this.$emit('close');
            }
        },
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control:first-child
    margin-bottom: 20px
</style>
