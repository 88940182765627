<template>
    <modal :title="title" :subtitle="subtitle" @save="save">
        <div class="control">
            <span>Фактическое значение</span>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="factValue" @blur="formatValue"/>
            </div>
        </div>
        <div class="control">
            <span>Плановое значение</span>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="planValue" @blur="formatValue"/>
            </div>
        </div>
        <div class="control">
            <span>Процент достижения</span>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="calculated" :disabled="$parent.selectedIndicator.calculation_type !== 'Вручную'"/>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import Calculation from '../Helpers/Calculation';
import FormatDataHelper from '../Helpers/Formatter';

export default {
    name: 'EditModalPreviousPeriodPreviousQuarter',
    components: {
        Modal,
    },
    data() {
        return {
            indicatorValues: null,
            title: `Редактировать данные показателя за ${this.$parent.selectedPeriod.title}`,
            subtitle: this.$parent.selectedIndicator.title,
            factValue: null,
            planValue: null,
            calculated: null,
        }
    },
    computed: {
        ...mapGetters([
            'periods',
            'user',
            'dateAccessOivStatus',
        ]),
    },
    watch: {
        planValue() {
            if (this.$parent.selectedIndicator.calculation_type !== 'Вручную') {
                this.calculated = this.calc();
            }
        },
        factValue() {
            if (this.$parent.selectedIndicator.calculation_type !== 'Вручную') {
                this.calculated = this.calc();
            }
        },
    },
    methods: {
        ...mapActions([
            'createIndicatorValues',
            'updateIndicatorValues',
        ]),
        calc() {
            return FormatDataHelper.localize(FormatDataHelper.formatPercent(Calculation.calcPercent(
                FormatDataHelper.normalize(this.planValue),
                FormatDataHelper.normalize(this.factValue),
                this.$parent.selectedIndicator.calculation_type
            )));
        },
        formatValue() {
            this.planValue = FormatDataHelper.localize(this.planValue);
            this.factValue = FormatDataHelper.localize(this.factValue);
        },
        save() {
            const data = {
                indicator_id: this.$parent.selectedIndicator.id,
                period_id: this.$parent.selectedPeriod.id,
                plan: this.planValue ? FormatDataHelper.normalize(this.planValue) : null,
                fact: this.factValue ? FormatDataHelper.normalize(this.factValue) : null,
                calculated: this.calculated ? FormatDataHelper.normalize(this.calculated) : null,
                type: 'year',
            };
            if (this.indicatorValues) {
                data.id = this.indicatorValues.id;
            }
            this.$store.commit('setIndicatorValues', data);
            if (this.indicatorValues) {
                this.updateIndicatorValues();
            } else {
                this.createIndicatorValues();
            }
            this.$emit('close');
        }
    },
    mounted() {
        this.indicatorValues = this.$parent.selectedIndicator.periods.find(p => p.period_id === this.$parent.selectedPeriod.id);
        if (this.indicatorValues) {
            this.factValue = FormatDataHelper.localize(this.indicatorValues.fact);
            this.planValue = FormatDataHelper.localize(this.indicatorValues.plan);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 20px
    &:last-child
        margin-bottom: 0
    span
        font-weight: 600
        font-size: $font-size
    .input-container
        margin-top: 8px

.btn-group
    .btn-container
        &:first-child
            margin-right: 15px
            width: 100px
        &:last-child
            width: 150px
</style>
