<template>
    <div class="configuration-step">
        <div class="control">
            <input type="file" ref="file" @change="prepare" accept=".xlsx"/>
        </div>
        <div v-if="isReadyUpload">
            <div class="control">
                <div class="input-container">
                    <label>Номер строки начала даных</label>
                    <input type="text" v-model="startDataRow"/>
                </div>
            </div>
            <div class="control">
                <div class="input-container">
                    <label>Колонка наименований</label>
                    <input type="text" v-model="colName"/>
                </div>
            </div>
            <div class="control">
                <div class="input-container">
                    <label>Колонка значений</label>
                    <input type="text" v-model="colValue"/>
                </div>
            </div>
            <div class="control">
                <div class="input-container">
                    <label>Колонка комментариев</label>
                    <input type="text" v-model="colComment"/>
                </div>
            </div>
            <div class="control">
                <select-container
                    :label="'Квартал привязки значений'"
                    :values="quarters"
                    :preselected="quarters[0]"
                    @select="assignQuarter"
                />
            </div>
            <div class="control">
                <btn-container
                    :primary="true"
                    :small="true"
                    :title="'Импортировать'"
                    :icon="'add'"
                    @click="submit"
                />
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import SelectContainer from '@/index/components/Admin/Import/Containers/Select.vue';

export default {
    components: {
        BtnContainer,
        SelectContainer,
    },
    data() {
        return {
            file: null,
            isReadyUpload: false,
            startDataRow: "1",
            colName: "A",
            colValue: "B",
            colComment: "C",
            assignedQuarterId: null,
            result: null,
        }
    },
    computed: {
        ...mapGetters([
            'quarters',
        ]),
    },
    watch: {
        quarters(qs) {
            this.assignedQuarterId = qs[0].id;
        }
    },
    methods: {
        ...mapActions([
            'uploadFile',
        ]),
        prepare() {
            if (this.$refs.file.files[0]) {
                this.file = this.$refs.file.files[0];
                this.isReadyUpload = true;
            } else {
                this.isReadyUpload = false;
            }
        },
        assignQuarter(q) {
            this.assignedQuarterId = q.id;
        },
        submit() {
            this.$store.state.file = this.file;
            this.$store.state.uploadConfig = {
                startDataRow: this.startDataRow,
                colName: this.colName,
                colValue: this.colValue,
                colComment: this.colComment,
                quarterId: this.assignedQuarterId,
            };
            this.uploadFile()
                .then((response) => {
                    this.$emit('commit', response.data.info);
                })
                .catch(e => console.log(e));
        }
    },
    created() {
        this.assignedQuarterId = this.quarters[0].id;
    },
    mounted() {

    }
}
</script>

<style lang="sass" scoped>
@import '../../../../assets/index.sass'

.configuration-step
    margin-top: 10px
    max-width: 240px
    .header
        font-size: large
    .control
        margin-top: 10px
</style>
