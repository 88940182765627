<template>
    <tooltip-filter :id="id" @apply="apply" :with-footer="true">
        <ul class="tooltip-indicator-list custom-scrollbar">
            <li>
                <div class="control">
                    <div class="checkbox-container" @click="toggleAll">
                        <input type="checkbox" :checked="selectedPeriods.length === periods.length"/>
                        <label>Все периоды</label>
                    </div>
                </div>
            </li>
            <li v-for="period in periods">
                <div class="control">
                    <div class="checkbox-container" @click="togglePeriod(period)">
                        <input type="checkbox" :checked="selectedPeriods.some(p => p.title === period.title)"/>
                        <label>{{ period.title }}</label>
                    </div>
                </div>
                <template v-if="period.quarters">
                    <ul class="quarters">
                        <li v-for="quarter in period.quarters">
                            <div class="control">
                                <div class="checkbox-container" @click="toggleQuarter(period, quarter)">
                                    <input type="checkbox"/>
                                    <label>{{ quarter.title }}</label>
                                </div>
                            </div>
                        </li>
                    </ul>
                </template>
            </li>
        </ul>
    </tooltip-filter>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '@/index/components/ReusableComponents/TooltipFilter.vue';

export default {
    name: 'TooltipTimeOptions',
    components: {
        TooltipFilter,
    },
    data() {
        return {
            id: 'tooltip-time-options',
            selectedPeriods: [],
        }
    },
    computed: {
        ...mapGetters([
            'periods',
        ]),
    },
    methods: {
        toggleAll() {
            if (this.selectedPeriods.length < this.periods.length) {
                this.selectedPeriods = this.periods;
            } else {
                this.selectedPeriods = [];
            }
        },
        togglePeriod(period) {
            if (this.selectedPeriods.some(p => p.title === period.title)) {
                this.selectedPeriods = this.selectedPeriods.filter(p => p.title !== period.title);
            } else {
                this.selectedPeriods.push(period);
            }
        },
        toggleQuarter(period, quarter) {

        },
        apply() {

        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.tooltip-indicator-list
    min-width: 250px
    margin: 10px 2px 0 0
    padding: 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    > li
        margin-right: 15px
        padding: 12px 0
        border-bottom: 1px solid $gray-200-color
        &:last-child
            border-bottom: none
        .control
            display: flex
            input
                display: inline-block
                margin-right: 8px
                width: auto
            label
                display: inline-block
                max-width: 260px
                font-size: $font-size
                font-weight: 500

.quarters
    display: flex
    margin: 0 0 0 22px
    padding: 0
    list-style-type: none
    li
        margin-top: 10px
        margin-right: 15px
        font-size: $font-size
        font-weight: 500

</style>
