<template>
    <div class="select-container">
        <div class="input-container" @click="isOptionsContainerVisible = !isOptionsContainerVisible">
            <label>Периоды</label>
            <input type="text" v-model="calculatedValue"/>
            <div class="control-elements">
                <!--<div class="element">-->
                    <!--<div>-->
                        <!--<img class="more" src="~@/index/assets/icons/reset.svg"/>-->
                    <!--</div>-->
                <!--</div>-->
                <div class="arrows">
                    <div>
                        <template v-if="isOptionsContainerVisible">
                            <img src="~@/index/assets/icons/chevron_up.svg"/>
                        </template>
                        <template v-else>
                            <img src="~@/index/assets/icons/chevron_down.svg"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="isOptionsContainerVisible">
            <tooltip-filter :id="id" :with-footer="true" @apply="apply" @close="cancel">
                <ul class="tooltip-indicator-list custom-scrollbar">
                    <li>
                        <div class="control">
                            <!--<div class="checkbox-container disabled" @click="toggleAllPeriods">-->
                            <div class="checkbox-container disabled" @click="">
                                <input type="checkbox" :checked="selectedPeriods.length === periods.length && selectedQuarters.length === 4"/>
                                <label>Все периоды</label>
                            </div>
                        </div>
                    </li>
                    <li v-for="period in periods">
                        <div class="control">
                            <!--<div class="checkbox-container disabled" @click="togglePeriod(period)">-->
                            <div class="checkbox-container disabled" @click="">
                                <input type="checkbox" :checked="selectedPeriods.some(p => p.value === period.value)"/>
                                <label>{{ period.title }}</label>
                            </div>
                        </div>
                        <template v-if="period.quarters">
                            <ul class="quarters">
                                <li v-for="quarter in period.quarters">
                                    <div class="control">
                                        <!--<div class="checkbox-container disabled" @click="toggleQuarter(period, quarter)">-->
                                        <div class="checkbox-container disabled" @click="">
                                            <input type="checkbox" :checked="selectedQuarters.some(q => q.value === quarter.value)"/>
                                            <label>{{ quarter.title }}</label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </li>
                </ul>
            </tooltip-filter>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TooltipFilter from '../components/ReusableComponents/TooltipFilter.vue';

export default {
    name: 'PeriodFilter',
    components: {
        TooltipFilter,
    },
    data() {
        return {
            isOptionsContainerVisible: false,
            id: 'tooltip-period-options',
            selectedPeriods: [],
            selectedQuarters: [],
            calculatedValue: '',
        }
    },
    computed: {
        ...mapGetters([
            'periods',
        ]),
    },
    watch: {
        periods() {
            this.toggleAllPeriods();
            this.toggleAllQuarters();
            this.calculateValue();
        },
    },
    methods: {
        toggleAllPeriods() {
            if (this.selectedPeriods.length < this.periods.length) {
                this.selectedPeriods = this.periods;
            } else {
                this.selectedPeriods = [];
            }
            this.toggleAllQuarters();
        },
        togglePeriod(period) {
            if (this.selectedPeriods.some(p => p.value === period.value)) {
                this.selectedPeriods = this.selectedPeriods.filter(p => p.value !== period.value);
            } else {
                this.selectedPeriods.push(period);
            }
            this.toggleAllQuarters();
        },
        toggleQuarter(period, quarter) {
            if (this.selectedQuarters.some(q => q.value === quarter.value)) {
                this.selectedQuarters = this.selectedQuarters.filter(q => q.value !== quarter.value);
                if (this.selectedQuarters.length === 0) {
                    this.selectedPeriods = this.selectedPeriods.filter(p => p.value !== period.value);
                }
            } else {
                this.selectedQuarters.push(quarter);
                if (!this.selectedPeriods.some(p => p.value === period.value)) {
                    this.selectedPeriods.push(period);
                }
            }
        },
        toggleAllQuarters() {
            if (this.selectedPeriods.length === 0) {
                this.selectedQuarters = [];
            } else {
                if (this.selectedPeriods.some(p => p.quarters && p.quarters.length)) {
                    this.selectedQuarters = this.selectedPeriods.find(p => p.quarters && p.quarters.length).quarters;
                } else {
                    this.selectedQuarters = [];
                }
            }
        },
        apply() {
            // this.selectedPeriods = this.selectedPeriods.sort(this.sort);
            // this.selectedQuarters = this.selectedQuarters.sort(this.sort);
            // this.$store.commit('setFilteredPeriods', JSON.parse(JSON.stringify(this.selectedPeriods)));
            // this.$store.commit('setFilteredQuarters', JSON.parse(JSON.stringify(this.selectedQuarters)));
            // this.calculateValue();
            this.isOptionsContainerVisible = false;
        },
        calculateValue() {
            if (this.selectedPeriods.length === 0) {
                this.calculatedValue = '';
            } else if (this.selectedPeriods.length === this.periods.length) {
                if (this.selectedQuarters.length > 0 && this.selectedQuarters.length < 4) {
                    const calculatedValues = this.selectedPeriods.map(p => {
                        if (p.quarters && p.quarters.length) {
                            return p.title + ' - ' + this.selectedQuarters.map(q => q.title).join(', ');
                        } else {
                            return p.title;
                        }
                    });
                    this.calculatedValue = calculatedValues.join(', ');
                } else {
                    this.calculatedValue = 'Все периоды ';
                }
            } else if (this.selectedPeriods.length === 1) {
                if (this.selectedQuarters.length) {
                    this.calculatedValue = this.selectedPeriods[0].title + ' - ' + this.selectedQuarters.map(q => q.title).join(', ');
                } else {
                    this.calculatedValue = this.selectedPeriods[0].title;
                }
            } else {
                this.calculatedValue = this.selectedPeriods.reduce((a, b) => {
                    if (this.selectedQuarters.length && a.quarters && a.quarters.length) {
                        return a.title + ' - ' + this.selectedQuarters.map(q => q.title).join(', ') + ', ' + b.title;
                    } else if (this.selectedQuarters.length && b.quarters && b.quarters.length) {
                        return a.title + ', ' + b.title + ' - ' + this.selectedQuarters.map(q => q.title).join(', ');
                    } else {
                        return a.title + ', ' + b.title;
                    }
                });
            }
        },
        sort(a, b) {
            return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
        },
        cancel() {
            this.isOptionsContainerVisible = false;
        },
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.tooltip-indicator-list
    min-width: 250px
    margin: 10px 2px 0 0
    padding: 0
    text-align: left
    list-style-type: none
    overflow-y: auto
    > li
        margin-right: 15px
        padding: 15px 0
        border-bottom: 1px solid $gray-200-color
        &:last-child
            border-bottom: none
        .control
            display: flex
            input
                display: inline-block
                margin-right: 8px
                width: auto
            label
                display: inline-block
                max-width: 260px
                font-size: $font-size
                font-weight: 500

.quarters
    display: flex
    margin: 8px 0 8px 22px
    padding: 0
    list-style-type: none
    li
        margin-top: 10px
        margin-right: 18px
        font-size: $font-size
        font-weight: 500

.checkbox-container
    &.disabled
        label::before
            border-color: $gray-400-color !important
            background-color: $gray-400-color !important

</style>
