class Formatter {
    localize(value) {
        return value ? value.toString().replace('.', ',') : value;
    }
    normalize(value) {
        return value ? value.toString().replace(',', '.') : value;
    }
    formatDecimal(value) {
        const formatter = new Intl.NumberFormat('ru-Ru', {
            style: 'decimal',
        });
        return isNaN(value) ? value : formatter.format(value);
    }
    formatPercent(value) {
        if (value) {
            return Number(value).toFixed();
        }
        return value;
    }
}

export default new Formatter;