<template>
    <div class="indication-management">
        <div class="header">
            <!--<btn-container-->
                <!--v-if="isFormVisible === false"-->
                <!--:primary="true"-->
                <!--:small="true"-->
                <!--:title="'Добавить индикацию'"-->
                <!--:icon="'add'"-->
                <!--@click=""-->
            <!--/>-->
        </div>
        <div class="content">
            <template v-if="isFormVisible">
                <indication-form @close="isFormVisible = false"/>
            </template>
            <template v-else>
                <h3>Подсветка &#8721; года</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Порог значения</th>
                        <th>Тип сравнения</th>
                        <th>Цвет</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="indication in indications">
                        <template v-if="indication.type === 'year'">
                            <tr>
                                <td>{{ indication.value }}</td>
                                <td>{{ indication.title }}</td>
                                <td>
                                    <div class="indication" :style="{ 'background-color': indication.color }"></div>
                                </td>
                                <td>
                                    <div class="control-list">
                                        <div class="control">
                                            <img class="frame"
                                                 src="~@/index/assets/icons/frame.svg"
                                                 @click="showIndicationForm(indication)"
                                            />
                                        </div>
                                        <div class="control">
                                            <span class="material-icons-outlined" @click="">delete</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
                <h3>Подсветка квартальных значений</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Порог значения</th>
                        <th>Тип сравнения</th>
                        <th>Цвет</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="indication in indications">
                        <template v-if="indication.type === 'quarter'">
                            <tr>
                                <td>{{ indication.value }}</td>
                                <td>{{ indication.title }}</td>
                                <td>
                                    <div class="indication" :style="{ 'background-color': indication.color }"></div>
                                </td>
                                <td>
                                    <div class="control-list">
                                        <div class="control">
                                            <img class="frame"
                                                 src="~@/index/assets/icons/frame.svg"
                                                 @click="showIndicationForm(indication)"
                                            />
                                        </div>
                                        <div class="control">
                                            <span class="material-icons-outlined" @click="">delete</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
                <h3>Интервалы подсветки</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Период</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="interval in indicationIntervals">
                        <template v-if="['year', 'quarter'].includes(interval.external_type)">
                            <tr>
                                <td>{{ interval.title }}</td>
                                <td>{{ calcPeriod(interval) }}</td>
                                <td>
                                    <div class="control-list">
                                        <div class="control">
                                            <img class="frame"
                                                 src="~@/index/assets/icons/frame.svg"
                                                 @click="showIndicationIntervalForm(interval)"
                                            />
                                        </div>
                                        <div class="control">
                                            <span class="material-icons-outlined" @click="">delete</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
                <h3>Заливка отчетного квартала</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Период</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="interval in indicationIntervals">
                        <template v-if="['report'].includes(interval.external_type)">
                            <tr>
                                <td>{{ interval.title }}</td>
                                <td>{{ calcPeriod(interval) }}</td>
                                <td>
                                    <div class="control-list">
                                        <div class="control">
                                            <img class="frame"
                                                 src="~@/index/assets/icons/frame.svg"
                                                 @click="showReportQuarterHighlight(interval)"
                                            />
                                        </div>
                                        <div class="control">
                                            <span class="material-icons-outlined" @click="">delete</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    </tbody>
                </table>
            </template>
        </div>
        <highlight-form v-if="isHighlightFormVisible" @close="isHighlightFormVisible = false"/>
        <indication-interval-form v-if="isIndicationIntervalFormVisible" @close="isIndicationIntervalFormVisible = false"/>
        <report-quarter-highlight-form v-if="isReportQuarterHighlightFormVisible" @close="isReportQuarterHighlightFormVisible = false"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BtnContainer from '@/index/components/ReusableComponents/BtnContainer.vue';
import HighlightForm from '@/index/components/Admin/Indication/HighlightForm.vue';
import IndicationIntervalForm from '@/index/components/Admin/Indication/IndicationIntervalForm.vue';
import ReportQuarterHighlightForm from '@/index/components/Admin/Indication/ReportQuarterHighlightForm.vue';
import dateformat from 'dateformat';
import { i18n } from 'dateformat';
i18n.dayNames = [
    "Вс",
    "Пн",
    "Вт",
    "Ср",
    "Чт",
    "Пт",
    "Сб",
    "Воскресенье",
    "Понедельник",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
i18n.monthNames = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
];

export default {
    name: 'Indication',
    components: {
        BtnContainer,
        HighlightForm,
        IndicationIntervalForm,
        ReportQuarterHighlightForm,
    },
    data() {
        return {
            title: 'Индикаторы',
            isHighlightFormVisible: false,
            isIndicationIntervalFormVisible: false,
            isReportQuarterHighlightFormVisible: false,
            selectedIndication: null,
            selectedInterval: null,
        }
    },
    computed: {
        ...mapGetters([
            'indications',
            'indicationIntervals',
        ]),
    },
    watch: {

    },
    methods: {
        showIndicationForm(indication) {
            this.selectedIndication = indication;
            this.isHighlightFormVisible = true;
        },
        removeIndication(indication) {
            // this.$store.commit('setRole', role);
            // this.removeRole();
        },
        showIndicationIntervalForm(interval) {
            this.selectedInterval = interval;
            this.isIndicationIntervalFormVisible = true;
        },
        showReportQuarterHighlight(interval) {
            this.selectedInterval = interval;
            this.isReportQuarterHighlightFormVisible = true;
        },
        calcPeriod(interval) {
            const dateFrom = dateformat(interval.config.from, 'd mmm');
            const dateTo = dateformat(interval.config.to, 'd mmm');
            return `${dateFrom} - ${dateTo}`;
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.indication-management
    .header
        position: relative
        line-height: 2.5em
        margin-bottom: 15px
        .btn-container
            position: absolute
            top: 0
            right: 0
            width: 150px
    .content
        > .control
            margin-bottom: 20px

h1, h2
    font-size: 18px

table
    border-collapse: collapse
    width: 100%
    max-width: 600px
    th
        padding: 10px 20px
        font-size: 12px
        font-weight: normal
        text-align: left
    td
        padding: 10px 20px
        font-size: 12px
        /*text-align: center*/
        border-bottom: 2px solid #fff
        background-color: $blue-light

.control-list
    display: flex
    justify-content: flex-end
    .control
        display: flex
        margin-right: 6px
        span
            font-size: 18px

.indication
    width: 20px
    height: 20px
    cursor: pointer

</style>
