let zoneOffset = 0;
let iZoneOffset = 0;
const zones = [];
const iZones = [];
let fixedZones = [];
let isAutoScrollRunning = false;
let isAwaitingRunning = false;
let awaitingTimer = null;
let ticking = false;

function createAltimeter(height, color) {
    const body = document.getElementsByTagName('body')[0];
    const altimeter = document.createElement('div');

    for (let i = 0, steps = parseInt(height / 100); i < steps; i++) {
        const delimiter = document.createElement('div');
        const scaleContainer = document.createElement('span');
        const scaleText = document.createTextNode(steps * (i + 1));
        scaleContainer.style.paddingLeft = '7px';
        scaleContainer.classList.add('scale-container');
        scaleContainer.appendChild(scaleText);

        delimiter.appendChild(scaleContainer);
        delimiter.style.display = 'flex';
        delimiter.style.flexDirection = 'column';
        delimiter.style.justifyContent = 'flex-end';
        delimiter.style.height = '100px';
        delimiter.style.backgroundColor = 'red';
        delimiter.classList.add('delimiter');
        altimeter.appendChild(delimiter);
    }

    altimeter.setAttribute('id', 'altimeter');
    altimeter.style.position = 'absolute';
    altimeter.style.top = 0;
    altimeter.style.width = '5px';
    altimeter.style.height = '10000px';
    altimeter.style.backgroundColor = 'red';
    body.appendChild(altimeter);
}

function test() {
    return;
    window.addEventListener('load', function () {
        // createAltimeter(10000);
        calcOffset();
        calcZones();
        addPageMargin();
    });

    // TODO: add preload
    document.addEventListener('scroll', function(event) {
        const body = document.getElementsByTagName('body')[0];
        const { top } = body.getBoundingClientRect();
        // TODO: define element fix-category-1
        // TODO: define element fix-category-2
        // TODO: define element fix-category-3
        const fixCategory1 = '';
        const fixCategory2 = '';
        const fixCategory3 = '';
        const fixZoneCategory1 = '';
        const fixZoneCategory2 = '';
        const fixZoneCategory3 = '';
        if (!ticking) {
            window.requestAnimationFrame(function() {
                fixZone(Math.abs(top));
                toggleGrouping(Math.abs(top));
                ticking = false;
            });
        }
        ticking = true;
    });
}

function calcOffset() {
    const table = document.getElementById('strategic-table');
    const headRow = table.querySelectorAll('thead tr')[0];
    const { height } = headRow.getBoundingClientRect();
    zoneOffset = height;
}

function calcZones() {
    const table = document.getElementById('strategic-table');
    const rows = table.querySelectorAll('tbody tr');
    let zoneId = 0;
    let c1 = {
        type: 'c1',
        el: null,
        boundings: null,
        startArea: null,
        endArea: null,
    };
    let c2 = {
        type: 'c2',
        el: null,
        boundings: null,
        startArea: null,
        endArea: null,
    };
    let c3 = {
        type: 'c3',
        el: null,
        boundings: null,
        startArea: null,
        endArea: null,
    };
    [...rows].forEach(function(el) {
        if (el.classList.contains('category-1')) {
            if (c1.startArea) {
                zones.push(Object.assign({}, c1));
                zones.push(Object.assign({}, c2));
                zones.push(Object.assign({}, c3));
                c1.startArea = null;
                c1.endArea = null;
                c2.startArea = null;
                c2.endArea = null;
                c3.startArea = null;
                c3.endArea = null;
            }
            c1.id = ++zoneId;
            c1.el = el;
            c1.boundings = el.getBoundingClientRect();
            c1.startArea += c1.boundings.top;
            c1.endArea += c1.boundings.bottom;
        } else if (el.classList.contains('category-2')) {
            if (c2.startArea) {
                zones.push(Object.assign({}, c2));
                zones.push(Object.assign({}, c3));
                c2.startArea = null;
                c2.endArea = null;
                c3.startArea = null;
                c3.endArea = null;
            }
            c2.id = ++zoneId;
            c2.el = el;
            c2.boundings = el.getBoundingClientRect();
            c1.endArea += c2.boundings.height;
            c2.startArea += c2.boundings.top;
            c2.endArea += c2.boundings.bottom;
        } else if (el.classList.contains('category-3')) {
            if (c3.startArea) {
                zones.push(Object.assign({}, c3));
                c3.startArea = null;
                c3.endArea = null;
            }
            c3.id = ++zoneId;
            c3.el = el;
            c3.boundings = el.getBoundingClientRect();
            c1.endArea += c3.boundings.height;
            c2.endArea += c3.boundings.height;
            c3.startArea += c3.boundings.top;
            c3.endArea += c3.boundings.bottom;
            c3.groupHeight = c1.boundings.height + c2.boundings.height + c3.boundings.height;
        } else {
            const boundings = el.getBoundingClientRect();
            c1.endArea += boundings.height;
            c2.endArea += boundings.height;
            c3.endArea += boundings.height;
            iZones.push({
                el,
                id: ++zoneId,
                parentId: c3.id,
                boundings,
                startArea: boundings.top,
                endArea: boundings.bottom,
                lines: el.querySelectorAll('.group-line-container span')
            });
        }

    });
    zones.push(c1, c2, c3);
    // console.log('zones', zones);
    // console.log('iZones', iZones);
}

function fixZone(pointer) {
    // console.log('pointer', pointer);
    if (isAwaitingRunning && !isAutoScrollRunning) {
        clearTimeout(awaitingTimer);
        awaitingTimer = null;
        isAwaitingRunning = false;
    }
    fixedZones = [];
    const originalPointer = pointer;
    pointer += zoneOffset;
    // console.log('pointer offset', pointer);
    // console.log('fix zone pointer', pointer);
    const c1 = zones.find(zone => zone.startArea <= pointer && zone.endArea > pointer && zone.el.classList.contains('category-1'));
    iZoneOffset = 0;
    if (c1) {
        c1.el.style.position = 'sticky';
        c1.el.style.top = '66.59px';
        c1.el.style.zIndex = 9;
        fixedZones.push(c1);
        iZoneOffset += c1.boundings.height;
        // console.log('c1', c1);
        pointer += c1.boundings.height;
        // console.log('c1.el.style.width', c1.el.style.width);
        const c2 = zones.find(zone => zone.startArea <= pointer && zone.endArea > pointer && zone.el.classList.contains('category-2'));
        // TODO: if c2 is hidden
        // console.log('c2', c2);
        if (c2) {
            c2.el.style.position = 'sticky';
            c2.el.style.top = '106.59px';
            c2.el.style.zIndex = 9;
            fixedZones.push(c2);
            iZoneOffset += c2.boundings.height;
            pointer += c2.boundings.height;
            const c3 = zones.find(zone => zone.startArea <= pointer && zone.endArea > pointer && zone.el.classList.contains('category-3'));
            // console.log('c3', c3);
            if (c3) {
                c3.el.style.position = 'sticky';
                c3.el.style.top = '146.59px';
                c3.el.style.zIndex = 9;
                fixedZones.push(c3);
                iZoneOffset += c3.boundings.height;
                const lastChild = iZones.filter(i => i.parentId === c3.id).pop();
                if (lastChild) {
                    pointer += c3.boundings.height;
                    // console.log('pointer', pointer);
                    const leftScroll = lastChild.boundings.top + lastChild.boundings.height - pointer;
                    const left = (leftScroll * 100) / lastChild.boundings.height;
                    // console.log('left', left);
                    // console.log('isAutoScrollRunning', isAutoScrollRunning);
                    // console.log('left to scroll', leftScroll);
                    if (left < 50 && !isAwaitingRunning && !isAutoScrollRunning) {
                        isAwaitingRunning = true;
                        let blockHeight = 0;
                        let searchId = lastChild.id + 1;
                        while (zones.find(z => z.id === searchId)) {
                            blockHeight += zones.find(z => z.id === searchId).boundings.height;
                            searchId++;
                        }
                        awaitingTimer = setTimeout(function () {
                            isAwaitingRunning = false;
                            isAutoScrollRunning = true;
                            autoScroll(originalPointer + leftScroll + blockHeight);
                        }, 1000);
                    }
                }
            }
        } else {
            // disable scroll?
            return;
        }
    }
}

function toggleGrouping(pointer) {
    pointer += zoneOffset + iZoneOffset;
    const iZone = iZones.find(i => i.startArea <= pointer && i.endArea > pointer);
    if (iZone) {
        for (let line of iZone.lines) {
            const calculatedHeight = Math.ceil(iZone.boundings.height - pointer + iZone.startArea);
            line.style.height = calculatedHeight + 'px';
        }
        const activeZoneIndex = iZones.findIndex(i => i.id === iZone.id);
        const before = iZones.slice(0, activeZoneIndex);
        const after = iZones.slice(activeZoneIndex + 1);
        before.map(i => {
            for (let line of i.lines) {
                line.style.height = '0';
            }
        });
        after.map(i => {
            for (let line of i.lines) {
                line.style.height = i.boundings.height + 'px';
            }
        });
    } else {
        const c = zones.find(zone => zone.startArea <= pointer && zone.endArea > pointer);
        if (c) {
            // iZones.filter(i => i.id > c.id).map(i => {
            //     for (let line of i.lines) {
            //         line.style.height = i.boundings.height + 'px';
            //     }
            // });
        } else {
            iZones.map(i => {
                for (let line of i.lines) {
                    line.style.height = i.boundings.height + 'px';
                }
            });
        }
    }
}

function autoScroll(to) {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'hidden';
    let from = window.scrollY;
    // console.log('from', from);
    // console.log('to', to);
    const timer = setInterval(function() {
        if (to >= from) {
            from++;
            window.scrollTo(0, from);
        } else {
            clearInterval(timer);
            isAutoScrollRunning = false;
            body.style.overflow = 'scroll';
            awaitingTimer = null;
        }
    }, 10);
}

function addPageMargin() {
    const windowHeight = window.innerHeight;
    const lastBlock = zones.reverse().find(z => z.type === 'c1');
    const lastBlockHeight = lastBlock.endArea - lastBlock.startArea;
    // console.log('windowHeight', windowHeight);
    // console.log('lastBlockHeight', lastBlockHeight);
    // console.log('zoneOffset', zoneOffset);
    // console.log('iZoneOffset', iZoneOffset);
    if (lastBlockHeight < windowHeight + zoneOffset) {
        // console.log('diff 1', lastBlockHeight + zoneOffset < windowHeight);
        // console.log('diff 2', windowHeight - zoneOffset - lastBlockHeight);
        const body = document.getElementsByTagName('body')[0];
        body.style.marginBottom = windowHeight - zoneOffset - lastBlockHeight + 'px';
    }
}

export default test