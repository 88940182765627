<template>
    <modal :title="title" :subtitle="subtitle" @save="save">
        <div class="control">
            <div class="label-container">
                <span>Фактическое значение</span>
            </div>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="factValue" :disabled="!(user && user.permissions[`indicator_values_fact_edit_quarter_${$parent.selectedQuarter.value}`])" @blur="formatValue"/>
            </div>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Плановое значение</span>
            </div>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="planValue" :disabled="!(user && user.permissions[`indicator_values_plan_edit_quarter_${$parent.selectedQuarter.value}`])" @blur="formatValue"/>
            </div>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Процент достижения</span>
            </div>
            <div class="input-container">
                <label>Текущее значение</label>
                <!--<input type="text" v-model="calculated" :disabled="$parent.selectedIndicator.calculation_type !== 'Вручную'"/>-->
                <input type="text" v-model="calculated" :disabled="!(user && user.permissions[`indicator_values_calculated_edit_quarter_${$parent.selectedQuarter.value}`])"/>
            </div>
        </div>
        <div class="control">
            <div class="label-container" v-if="user && user.permissions.indicator_comment_load">
                <span>Комментарий</span>
                <span class="action">Загрузить предыдущий</span>
            </div>
            <div class="text-container">
                <label>Введите комментарий</label>
                <textarea class="custom-scrollbar" id="comment" v-model="comment" :disabled="!(user && user.permissions.indicator_comment)"></textarea>
            </div>
        </div>
        <div class="save-error-container">
            <span class="title">{{ saveError.message }}</span>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '../components/ReusableComponents/Modal.vue';
import Calculation from '../Helpers/Calculation';
import FormatDataHelper from '../Helpers/Formatter';

export default {
    name: 'EditModalCurrentPeriodPreviousQuarter',
    components: {
        Modal,
    },
    data() {
        return {
            indicatorValues: null,
            periodParentId: null,
            title: `Редактировать данные показателя за ${this.$parent.selectedQuarter.title} квартал ${this.$parent.selectedPeriod.title}`,
            subtitle: this.$parent.selectedIndicator.title,
            factValue: null,
            planValue: null,
            calculated: null,
            comment: '',
            saveError: {
                source: null,
                message: null,
            },
        }
    },
    computed: {
        ...mapGetters([
            'periods',
            'user',
        ]),
    },
    watch: {
        planValue() {
            if (this.$parent.selectedIndicator.calculation_type !== 'Вручную') {
                this.calculated = this.calc();
            }
        },
        factValue() {
            if (this.$parent.selectedIndicator.calculation_type !== 'Вручную') {
                this.calculated = this.calc();
            }
            if (Number(FormatDataHelper.normalize(this.calculated)) >= 100) {
                document.getElementById('comment').classList.remove('error');
                this.saveError.message = null;
            }
        },
        comment() {
            if (this.comment && this.comment.length >= 10) {
                document.getElementById('comment').classList.remove('error');
                this.saveError.message = null;
            }
        }
    },
    methods: {
        ...mapActions([
            'createIndicatorValues',
            'updateIndicatorValues',
        ]),
        calc() {
            return FormatDataHelper.localize(FormatDataHelper.formatPercent(Calculation.calcPercent(
                FormatDataHelper.normalize(this.planValue),
                FormatDataHelper.normalize(this.factValue),
                this.$parent.selectedIndicator.calculation_type
            )));
        },
        formatValue() {
            this.planValue = FormatDataHelper.localize(this.planValue);
            this.factValue = FormatDataHelper.localize(this.factValue);
        },
        save() {
            this.saveError.source = null;
            this.saveError.message = null;
            document.getElementById('comment').classList.remove('error');
            if (this.factValue && Number(FormatDataHelper.normalize(this.calculated)) < 90) {
                if (!this.validate(this.comment)) {
                    this.saveError.message = 'Комментарий должен быть не менее 10 символов';
                    document.getElementById('comment').classList.add('error');
                    return;
                }
            }
            const data = {
                period_parent_id: this.periodParentId,
                indicator_id: this.$parent.selectedIndicator.id,
                period_id: this.$parent.selectedQuarter.id,
                plan: this.planValue ? FormatDataHelper.normalize(this.planValue) : null,
                fact: this.factValue ? FormatDataHelper.normalize(this.factValue) : null,
                calculated: this.calculated ? FormatDataHelper.normalize(this.calculated) : null,
                type: 'quarter',
                comment: this.comment,
            };
            if (this.indicatorValues) {
                data.id = this.indicatorValues.id;
            }
            this.$store.commit('setIndicatorValues', data);
            if (this.indicatorValues) {
                this.updateIndicatorValues();
            } else {
                this.createIndicatorValues();
            }
            this.$emit('close');
        },
        validate(value, type, rules) {
            // TODO: extend validation with types & rules
            if (value.toString().trim().length < 10) {
                return false;
            }
            return true;
        }
    },
    mounted() {
        this.periodParentId = this.$parent.selectedPeriod.id;
        this.factValue = this.$parent.selectedQuarter.fact;
        this.planValue = this.$parent.selectedQuarter.plan;
        this.indicatorValues = this.$parent.selectedIndicator.periods.find(p => p.period_id === this.$parent.selectedQuarter.id);
        if (this.indicatorValues) {
            this.factValue = FormatDataHelper.localize(this.indicatorValues.fact);
            this.planValue = FormatDataHelper.localize(this.indicatorValues.plan);
            this.comment = this.indicatorValues.comment || '';
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.control
    margin-bottom: 20px
    &:last-child
        margin-bottom: 0
    span
        font-weight: 600
        font-size: $font-size
        &.action
            color: $brand-primary-color
    [class*="container"]:not(:first-child)
        margin-top: 8px
    .label-container
        display: flex
        justify-content: space-between

.save-error-container
    position: relative
    margin-top: -18.39px
    margin-bottom: -18.39px
    .title
        color: $brand-primary-color
        font-size: 12px

</style>
