<template>
    <tooltip :id="id" :coordinates="coordinates" pos-type="left">
        <div class="section">
            <div class="control" @click="$emit('create-direction')">
                <span>Добавить новое направление</span>
            </div>
            <div class="control" @click="$emit('create-group')">
                <span>Добавить новую группу</span>
            </div>
            <div class="control" @click="$emit('create-target')">
                <span>Добавить новую цель</span>
            </div>
        </div>
        <div class="section">
            <div class="control" @click="moveUp">
                <span :class="{ disabled: direction.order === 1 }">Переместить уровнем выше</span>
            </div>
            <div class="control" @click="moveDown">
                <span :class="{ disabled: direction.order === max }">Переместить уровнем ниже</span>
            </div>
        </div>
        <div class="section">
            <div class="control" @click="$emit('edit-direction')">
                <span>Редактировать</span>
            </div>
            <div class="control" @click="$emit('delete-direction')">
                <span>Удалить</span>
            </div>
        </div>
    </tooltip>
</template>

<script>
import { mapActions } from 'vuex';
import Tooltip from '../components/ReusableComponents/Tooltip.vue';
import Api from '../store/api';

export default {
    name: 'TooltipDirectionMenu',
    components: {
        Tooltip,
    },
    props: ['direction', 'coordinates', 'max'],
    data() {
        return {
            id: 'tooltip-direction-menu',
        }
    },
    methods: {
        ...mapActions([
            'init',
        ]),
        moveUp() {
            if (this.direction.order > 1) {
                Api
                    .moveIndex(this.direction.id, -1)
                    .finally(() => this.init());
                this.$emit('close');
            }
        },
        moveDown() {
            if (this.direction.order < this.max) {
                Api
                    .moveIndex(this.direction.id, 1)
                    .finally(() => this.init());
                this.$emit('close');
            }
        }
    },
    mounted() {
        // const tooltip = document.getElementById(this.id);
        // const boundingClientRect = tooltip.getBoundingClientRect();
        // tooltip.style.top = this.coordinates.top + 'px';
        // tooltip.style.left = this.coordinates.left - boundingClientRect.width + 'px';
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../assets/index.sass'

.section
    margin: 6px 8px
    &:not(:last-child)
        border-bottom: 1px solid $gray-300-color
    .control
        margin: 4px 0
        padding: 8px 15px
        cursor: pointer
        &:hover
            background-color: $gray-100-color
        span
            font-size: $font-size
            font-weight: 500
            &.disabled
                color: $gray-500-color
</style>
