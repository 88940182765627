<template>
    <div class="btn-container" :class="{ 'btn-primary': primary, 'btn-small': small }">
        <div class="title-container">
            <div>
                <div>
                    <span class="material-icons">{{ icon }}</span>
                </div>
            </div>
        </div>
        <input type="button"/>
    </div>
</template>

<script>

export default {
    name: 'SmallBtnContainer',
    props: ['icon'],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

.btn-container
    width: 48px
    span
        color: $gray-700-color

</style>
