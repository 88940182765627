import { createStore } from 'vuex';
import source from './data';
import api from './api';
import dateformat from 'dateformat';

export default createStore({
    state: {
        initialized: false,
        data: [],
        oivs: [],
        unitMeasures: [],
        periods: [],
        quarters: [],
        direction: null,
        group: null,
        target: null,
        indicator: null,
        indicatorValues: null,
        indicators: [],
        directions: [],
        groups: source.groups,
        targets: source.targets,
        filteredData: [],
        filteredDirections: [],
        filteredOivs: [],
        filteredIndicators: [],
        filteredPeriods: [],
        filteredQuarters: [],
        isIndicatorFilterApplied: false,
        isOivFilterApplied: false,
        mpUserId: null,
        userSign: null,
        user: null,
        dateAccessOivStatus: false,
        reportQuarter: null,
        roles: [],
        operations: [],
        employees: [],
        role: null,
        interval: null,
        indications: [],
        indicationIntervals: [],
        indication: null,
        indicationInterval: null,
        settings: [],
        selectedSetting: null,
        selectedSettingInterval: null,
        importProcesses: [],
        userFilterConfig: null,
        file: null,
        uploadConfig: null,
        emailNotifications: [],
        selectedEmailNotification: null,
        yearValues: null,
        currentYear: null,
        selectedOiv: null,
    },
    mutations: {
        setInitialized:     ( state, status ) => state.initialized = status,
        setData:            ( state, data ) => state.data = data,
        setDirections:      ( state, directions ) => state.directions = directions,
        setGroups:          ( state, groups ) => state.groups = groups,
        setTargets:         ( state, targets ) => state.targets = targets,
        setIndicators:      ( state, indicators ) => state.indicators = indicators,
        setUnitMeasures:    ( state, unitMeasures ) => state.unitMeasures = unitMeasures,
        setPeriods:         ( state, periods ) => state.periods = periods,
        setQuarters:        ( state, quarters ) => state.quarters = quarters,
        setOivs:            ( state, oivs ) => state.oivs = oivs,
        // create/update data section
        setDirection:       ( state, direction ) => state.direction = direction,
        setGroup:           ( state, group ) => state.group = group,
        setTarget:          ( state, target ) => state.target = target,
        setIndicator:       ( state, indicator ) => state.indicator = indicator,
        setIndicatorValues: ( state, values ) => state.indicatorValues = values,
        // filter data section
        setFilteredData:        ( state, indicators ) => state.filteredData = indicators,
        setFilteredDirections:  ( state, directions ) => state.filteredDirections = directions,
        setFilteredOivs:        ( state, oivs ) => {
            state.filteredOivs = oivs;
            state.isOivFilterApplied = state.filteredOivs.length < state.oivs.length;
        },
        setFilteredIndicators:  ( state, indicators ) => {
            state.filteredIndicators = indicators;
            state.isIndicatorFilterApplied = state.filteredIndicators.length < state.indicators.length;
        },
        setFilteredPeriods:     ( state, periods ) => state.filteredPeriods = periods,
        setFilteredQuarters:    ( state, quarters ) => state.filteredQuarters = quarters,
        isIndicatorFilterApplied:   ( state, value ) => state.isIndicatorFilterApplied = value,
        isOivFilterApplied:         ( state, value ) => state.isOivFilterApplied = value,
        // access data section
        setUserSign:        ( state, sign ) => state.userSign = sign,
        setUser:            ( state, user ) => state.user = user,
        setDateAccessOivStatus: ( state, status ) => state.dateAccessOivStatus = status,
        setReportQuarter: ( state, quarter ) => state.reportQuarter = quarter,
        setRoles: ( state, roles ) => state.roles = roles,
        setOperations: ( state, operations ) => state.operations = operations,
        setEmployees: ( state, employees ) => state.employees = employees,
        setRole: ( state, role ) => state.role = role,
        setInterval: ( state, interval ) => state.interval = interval,
        setIndications: ( state, indications ) => state.indications = indications,
        setIndicationIntervals: ( state, indicationIntervals ) => state.indicationIntervals = indicationIntervals,
        setIndication: ( state, indication ) => state.indication = indication,
        setIndicationInterval: ( state, indicationInterval ) => state.indicationInterval = indicationInterval,
        setSettings: ( state, settings ) => state.settings = settings,
        setSelectedSetting: ( state, setting ) => state.selectedSetting = setting,
        setSelectedSettingInterval: ( state, interval ) => state.selectedSettingInterval = interval,
        setImportProcesses: ( state, processes ) => state.importProcesses = processes,
        setUserFilterConfig: ( state, filterConfig ) => state.userFilterConfig = filterConfig,
        setEmailNotifications: ( state, notifications ) => state.emailNotifications = notifications,
        setSelectedEmailNotification: ( state, notification ) => state.selectedEmailNotification = notification,
        setYearValues: ( state, values ) => state.yearValues = values,
        setCurrentYear: ( state, year ) => state.currentYear = year,
        setSelectedOiv: ( state, oiv ) => state.selectedOiv = oiv,
    },
    getters: {
        initialized:        state => state.initialized,
        data:               state => state.data,
        oivs:               state => state.oivs,
        unitMeasures:       state => state.unitMeasures,
        periods:            state => state.periods,
        quarters:           state => state.quarters,
        directions:         state => state.directions,
        groups:             state => state.groups,
        targets:            state => state.targets,
        indicators:         state => state.indicators,
        filteredData:       state => state.filteredData,
        filteredDirections: state => state.filteredDirections,
        filteredOivs:       state => state.filteredOivs,
        filteredIndicators: state => state.filteredIndicators,
        filteredPeriods:    state => state.filteredPeriods,
        filteredQuarters:   state => state.filteredQuarters,
        isIndicatorFilterApplied: state => state.isIndicatorFilterApplied,
        isOivFilterApplied: state => state.isOivFilterApplied,
        user:               state => state.user,
        dateAccessOivStatus: state => state.dateAccessOivStatus,
        reportQuarter:      state => state.reportQuarter,
        roles:              state => state.roles,
        operations:         state => state.operations,
        employees:          state => state.employees,
        indications:        state => state.indications,
        indicationIntervals: state => state.indicationIntervals,
        settings:           state => state.settings,
        importProcesses:    state => state.importProcesses,
        userFilterConfig:   state => state.userFilterConfig,
        emailNotifications: state => state.emailNotifications,
        yearValues:         state => state.yearValues,
        currentYear:        state => state.currentYear
    },
    actions: {
        init({ dispatch }) {
            dispatch('getData');
            dispatch('getDirections');
            dispatch('getOivs');
        },
        getData({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.loadData();
                    commit('setData', response.data.data.data);
                    // commit('setDirections', response.data.data.directions);
                    commit('setGroups', response.data.data.groups);
                    commit('setTargets', response.data.data.targets);
                    commit('setIndicators', response.data.data.indicators);
                    commit('setUnitMeasures', response.data.data.unitMeasures);
                    commit('setPeriods', response.data.data.periods);
                    commit('setQuarters', response.data.data.quarters);
                    // commit('setOivs', response.data.data.oivs);
                    commit('setFilteredData', JSON.parse(JSON.stringify(response.data.data.data)));
                    // commit('setFilteredDirections', JSON.parse(JSON.stringify(response.data.data.directions)));
                    commit('setFilteredQuarters', JSON.parse(JSON.stringify(response.data.data.quarters)));
                    // commit('setFilteredOivs', JSON.parse(JSON.stringify(response.data.data.oivs)));
                    // if (state.userFilterConfig && state.userFilterConfig.indicators.length) {
                    //     commit('setFilteredIndicators', state.indicators.filter(indicator => state.userFilterConfig.indicators.includes(indicator.id)));
                    // } else {
                        commit('setFilteredIndicators', JSON.parse(JSON.stringify(response.data.data.indicators)));
                    // }
                    commit('setYearValues', response.data.data.yearValues);
                    dispatch('getRoles');
                    dispatch('getOperations');
                    dispatch('getEmployees');
                    dispatch('getIndications');
                    dispatch('getIndicationIntervals');
                    dispatch('getSettings');
                    // dispatch('getImportProcesses');
                    dispatch('getEmailNotifications');
                    dispatch('getCurrentYear');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getOivs({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.loadOivs();
                    commit('setOivs', response.data.data);
                    commit('setFilteredOivs', JSON.parse(JSON.stringify(response.data.data)));
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getDirections({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.loadDirections();
                    commit('setDirections', response.data.data);
                    commit('setFilteredDirections', JSON.parse(JSON.stringify(response.data.data)));
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createDirection({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.createDirection(state.direction);
                    dispatch('getDirections');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateDirection({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateDirection(state.direction);
                    dispatch('getDirections');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        removeDirection({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.removeDirection(state.direction.id);
                    dispatch('getDirections');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createGroup({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.createGroup(state.group);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateGroup({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateGroup(state.group);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        removeGroup({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.removeGroup(state.group.id);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createTarget({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    console.log('state target', state.target);
                    await api.createTarget(state.target);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateTarget({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateTarget(state.target);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        removeTarget({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.removeTarget(state.target.id);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createIndicator({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.createIndicator(state.indicator);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateIndicator({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateIndicator(state.indicator);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        removeIndicator({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.removeIndicator(state.indicator.id);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createIndicatorValues({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.createIndicatorValues(state.indicatorValues);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateIndicatorValues({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateIndicatorValues(state.indicatorValues);
                    dispatch('getData');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        checkUser({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.checkUser(state.userSign);
                    state.mpUserId = response.data.data.id;
                    if (state.mpUserId) {
                        dispatch('getUser');
                    }
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getUser({ state, commit, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.getUserByMpId(state.mpUserId);
                    commit('setUser', response.data.data);
                    dispatch('getFilter');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        exportData() {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.exportXlsx();
                    const fileURL = window.URL.createObjectURL(new Blob([ response.data ], { type: response.headers['content-type'] }));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        'download',
                        `Цели Комплекса - ${dateformat(new Date(), 'yyyy.mm.dd')}.xlsx`
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getOperations({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.getOperations();
                    commit('setOperations', response.data.data);
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getEmployees({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.getEmployees();
                    commit('setEmployees', response.data);
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getRoles({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.getRoles();
                    commit('setRoles', response.data.data);
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createRole({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.createRole(state.role);
                    dispatch('getRoles');
                    dispatch('getUser');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateRole({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateRole(state.role);
                    dispatch('getRoles');
                    dispatch('getUser');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        removeRole({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.removeRole(state.role.id);
                    dispatch('getRoles');
                    dispatch('getUser');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        createInterval({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.createInterval(state.interval);
                    dispatch('getRoles');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateInterval({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateInterval(state.interval);
                    dispatch('getRoles');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        removeInterval({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.removeInterval(state.interval.id);
                    dispatch('getRoles');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getIndications({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.getIndications();
                    commit('setIndications', response.data.data);
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getIndicationIntervals({ commit }) {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.getIndicationIntervals();
                    commit('setIndicationIntervals', response.data.data);
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateIndication({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateIndication(state.indication);
                    dispatch('getIndications');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        updateIndicationInterval({ state, dispatch }) {
            return new Promise(async (resolve, reject) => {
                try {
                    await api.updateIndicationInterval(state.indicationInterval);
                    dispatch('getIndicationIntervals');
                    resolve();
                } catch (e) {
                    console.error(e);
                    reject(e);
                }
            });
        },
        getSettings({ commit }) {
            api.getSettings()
                .then(response => commit('setSettings', response.data.data))
                .catch(e => console.error(e));
        },
        updateSetting({ state, dispatch }) {
            api.updateSetting(state.selectedSetting)
                .then(response => dispatch('getSettings'))
                .catch(error => console.error(error));
        },
        updateSettingInterval({ state, dispatch }) {
            api.updateSettingInterval(state.selectedSettingInterval)
                .then(response => dispatch('getSettings'))
                .catch(error => console.error(error));
        },
        getImportProcesses({ commit }) {
            api.getImportProcesses()
                .then(response => commit('setImportProcesses', response.data.data))
                .catch(error => console.log(error));
        },
        getFilter({ state, commit }) {
            api.getUserFilterConfig(state.user.id)
                .then(response => {
                    const config = response.data.data;
                    if (Object.keys(config).length > 0) {
                        commit('setUserFilterConfig', config);
                        if (config.oivs && config.oivs.length) {
                            commit('setFilteredOivs', state.oivs.filter(oiv => config.oivs.includes(oiv.id)));
                        }
                    }
                })
                .catch(error => console.error(error));
        },
        saveFilter({ state }) {
            if (state.userFilterConfig.id) {
                api.updateUserFilterConfig(state.userFilterConfig);
            } else {
                api.createUserFilterConfig({
                    userId: state.user.id,
                    config: state.userFilterConfig,
                });
            }
        },
        uploadFile({ state }) {
            const formData = new FormData();
            formData.append('file', state.file);
            formData.append('config', JSON.stringify(state.uploadConfig));
            return api.upload(formData);
        },
        getEmailNotifications({ commit }) {
            api.getEmailNotifications()
                .then(response => commit('setEmailNotifications', response.data.data));
        },
        updateEmailNotification({ state, dispatch }) {
            api.updateEmailNotification(state.selectedEmailNotification)
                .then(response => dispatch('getEmailNotifications'));
        },
        notifyProcessImportResult() {
            api.notifyProcessImportResult();
        },
        getCurrentYear({ state, commit }) {
            api.getCurrentYear()
                .then(response => {
                    const currentYearValue = response.data.data.config.value;
                    commit('setFilteredPeriods', JSON.parse(JSON.stringify(state.periods.filter(period => [-1, 0, 1].includes(period.value - currentYearValue)))));
                    commit('setCurrentYear', currentYearValue);
                });
        },
        updateCurrentYear({ state, dispatch }) {
            api.updateCurrentYear(state.currentYear)
                .then(response => dispatch('getData'));
        },
        createOiv({ state, dispatch }) {
            api.createOiv(state.selectedOiv)
                .then(response => dispatch('getOivs'));
        },
        updateOiv({ state, dispatch }) {
            api.updateOiv(state.selectedOiv)
                .then(response => dispatch('getOivs'));
        }
    },
    modules: {

    }
})
