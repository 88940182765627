<template>
    <modal :title="modalTitle" @save="save" @close="$emit('close')">
        <div class="control">
            <div class="label-container">
                <span>Пороговое значение</span>
            </div>
            <div class="input-container">
                <label>Текущее значение</label>
                <input type="text" v-model="value"/>
            </div>
        </div>
        <div class="control">
            <div class="label-container">
                <span>Тип сравнения</span>
            </div>
            <select-compare-type @select="selectCompare" :compare="compare"/>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/index/components/ReusableComponents/Modal.vue';
import SelectCompareType from '@/index/components/Admin/Indication/SelectCompareType.vue';

export default {
    components: {
        Modal,
        SelectCompareType,
    },
    data() {
        return {
            modalTitle: 'Редактировать значения подсветки',
            title: '',
            compare: null,
            value: null,
        }
    },
    methods: {
        ...mapActions([
            'updateIndication',
        ]),
        selectCompare(compareType) {
            this.compare = compareType.compare;
            this.title = compareType.title;
        },
        save() {
            // TODO: save
            this.$store.commit('setIndication', {
                id: this.$parent.selectedIndication.id,
                title: this.title,
                compare: this.compare,
                value: this.value,
                type: this.$parent.selectedIndication.type,
                color: this.$parent.selectedIndication.color,
                order: this.$parent.selectedIndication.order,
            });
            this.updateIndication();
            this.$emit('close');
        }
    },
    created() {
        if (this.$parent.selectedIndication) {
            this.title = this.$parent.selectedIndication.title;
            this.compare = this.$parent.selectedIndication.compare;
            this.value = this.$parent.selectedIndication.value;
        }
    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../../assets/index.sass'

.control
    margin-bottom: 20px

</style>
