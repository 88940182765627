<template>
    <div class="select-container">
        <div class="input-container" @click="isOptionsContainerVisible = !isOptionsContainerVisible">
            <label>{{ label }}</label>
            <input type="text" :value="value"/>
            <div class="control-elements">
                <!--<div class="element">-->
                    <!--<div>-->
                        <!--<img class="more" src="~@/index/assets/icons/reset.svg"/>-->
                    <!--</div>-->
                <!--</div>-->
                <div class="arrows">
                    <div>
                        <template v-if="isOptionsContainerVisible">
                            <img src="~@/index/assets/icons/chevron_up.svg"/>
                        </template>
                        <template v-else>
                            <img src="~@/index/assets/icons/chevron_down.svg"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="isOptionsContainerVisible">
            <template v-if="label === 'Период'">
                <tooltip-time-options @close="isOptionsContainerVisible = false"/>
            </template>
        </template>
    </div>
</template>

<script>
import TooltipTimeOptions from '@/index/components/ReusableComponents/TooltipTimeOptions.vue'

export default {
    name: 'SelectContainer',
    props: ['label', 'value', 'items'],
    components: {
        TooltipTimeOptions,
    },
    data() {
        return {
            isOptionsContainerVisible: false,
        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '../../assets/index.sass'

</style>
