const axios = require('axios');

const API_URL = process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:3000';

module.exports.loadData = () => axios.get(`${API_URL}/api/data`);
module.exports.loadDirections = () => axios.get(`${API_URL}/api/directions`);
module.exports.loadOivs = () => axios.get(`${API_URL}/api/oivs`);
// module.exports.loadUnitMeasures = function () {
//     return axios.get(`${API_URL}/api/unit-measures`);
// };
// module.exports.loadPeriods = function () {
//     return axios.get(`${API_URL}/api/periods`);
// };
// module.exports.loadQuarters = function () {
//     return axios.get(`${API_URL}/api/quarters`);
// };
module.exports.createDirection  = (data) => axios.post(`${API_URL}/api/direction`, data);
module.exports.updateDirection  = (direction) => axios.put(`${API_URL}/api/direction/${direction.id}`, direction);
module.exports.removeDirection  = (id) => axios.delete(`${API_URL}/api/direction/${id}`);
module.exports.createGroup      = (data) => axios.post(`${API_URL}/api/group`, data);
module.exports.updateGroup      = (group) => axios.put(`${API_URL}/api/group/${group.id}`, group);
module.exports.removeGroup      = (id) => axios.delete(`${API_URL}/api/group/${id}`);
module.exports.createTarget     = (data) => axios.post(`${API_URL}/api/target`, data);
module.exports.updateTarget     = (target) => axios.put(`${API_URL}/api/target/${target.id}`, target);
module.exports.removeTarget     = (id) => axios.delete(`${API_URL}/api/target/${id}`);
module.exports.createIndicator  = (data) => axios.post(`${API_URL}/api/indicator`, data);
module.exports.updateIndicator  = (indicator) => axios.put(`${API_URL}/api/indicator/${indicator.id}`, indicator);
module.exports.removeIndicator  = (id) => axios.delete(`${API_URL}/api/indicator/${id}`);
module.exports.createIndicatorValues = (data) => axios.post(`${API_URL}/api/indicator/${data.indicator_id}/values`, data);
module.exports.updateIndicatorValues = (indicatorValues) => axios.put(`${API_URL}/api/indicator/${indicatorValues.indicator_id}/values/${indicatorValues.id}`, indicatorValues);
module.exports.checkUser        = (userSign) => axios.get(`${API_URL}/api/user/check?user_sign=${userSign}`, { withCredentials: true });
module.exports.getUserByMpId    = (mpUserId) => axios.get(`${API_URL}/api/user?mp_user_id=${mpUserId}`);
module.exports.exportXlsx       = () => axios.post(`${API_URL}/api/export/xlsx`, {}, { responseType: 'blob' });
module.exports.getOperations    = () => axios.get(`${API_URL}/api/operations`);
module.exports.getEmployees     = () => {
    return axios({
        method: 'get',
        url: 'https://reports.control-mos.ru/new-api/employee',
        headers: {
            'x-cnd': 3948765589675867,
        },
    });
};
module.exports.getRoles         = () => axios.get(`${API_URL}/api/role/roles`);
module.exports.createRole       = (data) => axios.post(`${API_URL}/api/role`, data);
module.exports.updateRole       = (role) => axios.put(`${API_URL}/api/role/${role.id}`, role);
module.exports.removeRole       = (id) => axios.delete(`${API_URL}/api/role/${id}`);
module.exports.createInterval   = (data) => axios.post(`${API_URL}/api/role/interval`, data);
module.exports.updateInterval   = (interval) => axios.put(`${API_URL}/api/role/interval/${interval.id}`, interval);
module.exports.removeInterval   = (id) => axios.delete(`${API_URL}/api/role/interval/${id}`);
module.exports.getIndications   = () => axios.get(`${API_URL}/api/indications`);
module.exports.updateIndication = (indication) => axios.put(`${API_URL}/api/indication/${indication.id}`, indication);
module.exports.getIndicationIntervals = () => axios.get(`${API_URL}/api/indication/intervals`);
module.exports.updateIndicationInterval = (interval) => axios.put(`${API_URL}/api/indication/interval/${interval.id}`, interval);
module.exports.getSettings      = () => axios.get(`${API_URL}/api/admin/settings`);
module.exports.updateSetting    = (setting) => axios.put(`${API_URL}/api/admin/setting/${setting.id}`, setting);
module.exports.updateSettingInterval = (interval) => axios.put(`${API_URL}/api/admin/setting/interval/${interval.id}`, interval);
module.exports.getImportProcesses = () => axios.get(`${API_URL}/api/import`);
module.exports.getUserFilterConfig = (userId) => axios.get(`${API_URL}/api/filter/user/${userId}`);
module.exports.createUserFilterConfig = (config) => axios.post(`${API_URL}/api/filter`, config);
module.exports.updateUserFilterConfig = (config) => axios.put(`${API_URL}/api/filter/${config.id}`, config);
module.exports.upload           = (formData) => axios.post(`${API_URL}/api/import/upload`, formData, { contentType: 'multipart/form-data' });
module.exports.getChartTotal    = () => axios.get(`${API_URL}/api/chart/total`);
module.exports.getChartOivs     = () => axios.get(`${API_URL}/api/chart/oivs`);
module.exports.getRiskZoneData  = () => axios.get(`${API_URL}/api/risk-zone`);
module.exports.getEmailNotifications = () => axios.get(`${API_URL}/api/admin/setting/email-notifications`);
module.exports.updateEmailNotification = (notification) => axios.put(`${API_URL}/api/admin/setting/email-notification/${notification.id}`, notification);
module.exports.notifyProcessImportResult = () => axios.post(`${API_URL}/api/import/notify`);
module.exports.getCurrentYear = () => axios.get(`${API_URL}/api/admin/setting/current-year`);
module.exports.updateCurrentYear = value => axios.put(`${API_URL}/api/admin/setting/current-year`, { value });
module.exports.createOiv = data => axios.post(`${API_URL}/api/oiv`, data);
module.exports.updateOiv = oiv => axios.put(`${API_URL}/api/oiv/${oiv.id}`, oiv);
module.exports.moveIndex = (id, index) => axios.post(`${API_URL}/api/index/${id}/move`, { index });
module.exports.checkServerStatusUpdate = () => axios.get(`${API_URL}/api/check/server-status-update`);